import { AngularFirestore } from '@angular/fire/firestore';
import { HelperService } from './../../services/helper.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from './../../services/user.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { PostsService } from './../../services/posts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Event } from '../../models/events';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';
import * as firebase from 'firebase/app';
import * as geofirex from 'geofirex';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
  isSubmitted = false;
  eventForm: FormGroup;
  lat;
  lng;
  dP;
  dN;
  mail;
  userid;
  image: string = null;
  eventData = new Event();
  eventId;
  private unsubscribe$ = new Subject<void>();
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  form: FormGroup;
  submitType: string;
  formType: string;
  dataPostedUrl: string;
  isNewForm: Observable<boolean>;
  isLoading = false;
  streetNumber: string = '';
  route: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  country: string = '';

  constructor(
    private postService: PostsService,
    private storage: AngularFireStorage,
    private router: Router,
    private userService: UserService,
    private db: AngularFirestore,
    private auth: AuthService,
    private message: HelperService,
    private formBuilder: FormBuilder,
    private zone: NgZone,
    private loadingController: LoadingController
  ) { 
  }

  ngOnInit() {
    this.getCurrentUser();
    this.createForm();
  }

  createForm() {
    this.eventForm = this.formBuilder.group({
      title: ['', Validators.required ],
      description: ['', Validators.required ],
      fee: ['', Validators.required ],
      time: ['', Validators.required ],
      startdate: ['', Validators.required ],
      finishdate: ['', Validators.required ],
      searchAddress: ['', Validators.required],
      streetnumber: [this.streetNumber],
      route: [this.route],
      city: [this.city],
      state: [this.state],
      country: [this.country]
    });
  }

  resetFields() {
    this.eventForm = this.formBuilder.group({
      title: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      fee: new FormControl('', Validators.required),
      time: new FormControl('', Validators.required),
      startdate: new FormControl('', Validators.required),
      finishdate: new FormControl('', Validators.required),
      author: new FormControl('', Validators.required),
      location: new FormControl('', Validators.required),
      streetnumber: new FormControl('', Validators.required),
      route: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      searchAddress: new FormControl('', Validators.required)
    });
  }

  onSubmit(eventForm) {
    if (!eventForm.valid ) {
      this.isSubmitted = true;
      this.message.validationToast('Please Fill All Required Fields', 2000, 'top', 'danger');
    } else {
      this.createEvent(eventForm)
    .then(
      res => {
        this.resetFields();
        this.router.navigate(['/home']);
      }
    )
    }}

  setPostFormData(postFormData) {
    this.eventData.title = postFormData.title;
    this.eventData.description = postFormData.description;
  }

  getCurrentUser() {
    this.auth.getAuthState().subscribe(
      user => {
        if (user) {
          this.userService.retrieveUserDocument(user.uid).subscribe(
            userDoc => {
              if (userDoc) {
                this.dP = userDoc.displayPicture;
                this.dN = userDoc.displayName;
                this.mail = userDoc.email;
                this.userid = userDoc.uid;
              }
            });
        } else {

        }
    });
  }

  setAddressFromMap(addressObj) {
    this.zone.run(() => {
      this.streetNumber = addressObj.street_number;
      this.route = addressObj.route;
      this.city = addressObj.locality;
      this.state = addressObj.admin_area_1;
      this.zipcode = addressObj.postal_code;
      this.country = addressObj.country;
    });
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': this.route + this.route + this.city }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          this.zone.run(() => {
            this.lat = results[0].geometry.location.lat();
            this.lng = results[0].geometry.location.lng();
            console.log(this.lat, this.lng);
          })
        } else {
          alert('Error - ' + results + ' & Status - ' + status);
        }
      });
  }

  createEvent(eventForm) {
    const geo = geofirex.init(firebase);
    const position = geo.point(this.lat, this.lng);
    return this.db.collection('feed').add({
      title: eventForm.value.title,
      description: eventForm.value.description,
      fee: eventForm.value.fee,
      time: eventForm.value.time,
      coordinates: position,
      type: 'Event',
      startdate: eventForm.value.startdate,
      finishdate: eventForm.value.finishdate,
      author: this.dN,
      location: eventForm.value.searchAddress,
      streetnumber: this.streetNumber || null,
      route: this.route,
      city: this.city,
      state: this.state,
      country: this.country,
      Applications: 0,
      views: 0,
      isFeatured: false,
      authorId: this.userid,
      createdDate: Date.now(),
      authorimage: this.dP,
      image: this.image
    }).then(() =>  this.message.messageToast('Program Posted Successfully', 2000, 'top', 'primary'))
  }

async uploadEventImage(event) {
  const loading = await this.loadingController.create({
    message: 'Uploading Image',
    duration: 4000,
    spinner: 'bubbles'
  });
  const file = event.target.files[0];
  const path = `eventposts/${file.name}`;
  if ( file.type.split('/')[0] !== 'image') {
      return alert('Only Image Files');
    } else {
  await loading.present();
  const task = this.storage.upload(path, file);
  const ref = this.storage.ref(path);
  this.uploadPercent = task.percentageChanges();
  console.log('Image uploaded!');
  task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL();
          this.downloadURL.subscribe(url => (this.image = url));
        })
      )
      .subscribe();
    }
  }

}
