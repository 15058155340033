import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-getstart',
  templateUrl: './getstart.page.html',
  styleUrls: ['./getstart.page.scss'],
})
export class GetstartPage implements OnInit {

  constructor(private modalController: ModalController,private router: Router) { }

  emailform = new FormGroup({
    user: new FormControl('', [
      Validators.required,
    ]),
  })

    ngOnInit() {
    }
    onSubmit(emailform) {
      this.modalController.dismiss()
      if (emailform.value.user === 'Freelancer') {
        this.router.navigate(['/register']);
      } else if (emailform.value.user === 'Employer') {
        this.router.navigate(['/register-employer']);
      }
    }

    close(){
    this.modalController.dismiss()
    }
    home(){
      this.modalController.dismiss()
      this.router.navigate(['/register']);
    }
}
