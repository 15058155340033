import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';
// import { Network } from '@ionic-native/network/ngx';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private router: Router,
    // private network: Network,
    private toastController: ToastController ,
    private loadingController: LoadingController) { }


    checkNetwork() {
      // this.network.onConnect().subscribe(() => console.log('this is network is active') );
      // this.network.onDisconnect().subscribe(() => console.log('network was disconnected :-(') );

    }

  async messageToast(message: string, duration: number, positions, color: string) {
    const toast = await this.toastController.create({
      message,
      duration,
      animated: true,
      color,
      position: positions,
      translucent: false,
      buttons: [
        // {
        //   side: 'start',
        //   icon: 'star',
        //   text: 'Favorite',
        //   handler: () => {
        //     console.log('Favorite clicked');
        //   }
        // },
        
        {
          text: 'Dismiss',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }
  async coinsToast(message: string, duration: number, positions, color: string) {
    const toast = await this.toastController.create({
      message,
      // duration,
      animated: true,
      color,
      position: positions,
      translucent: false,
      buttons: [
        {
          side: 'end',
          icon: 'cash',
          text: 'Buy Coins',
          handler: () => {
            this.router.navigate(['/pricelist']);
          }
        },
        // {
        //   text: 'Buy Coins',
        //   role: '' ,
        //   handler: () => {
        //     this.router.navigate(['/pricelist']);
        //   }
        // }
      ]
    });
    toast.present();
  }
  async subscribeToast(message: string, duration: number, positions, color: string) {
    const toast = await this.toastController.create({
      message,
      // duration,
      animated: true,
      color,
      position: positions,
      translucent: false,
      buttons: [
        {
          side: 'end',
          icon: 'cash',
          text: 'Subscribe',
          handler: () => {
            this.router.navigate(['/subcription']);
          }
        },
        // {
        //   text: 'Buy Coins',
        //   role: '' ,
        //   handler: () => {
        //     this.router.navigate(['/pricelist']);
        //   }
        // }
      ]
    });
    toast.present();
  }
  async validationToast(message: string, duration: number, positions, color: string) {
    const toast = await this.toastController.create({
      message,
      // duration,
      animated: true,
      color,
      position: positions,
      translucent: false,
      buttons: [
        // {
        //   side: 'start',
        //   icon: 'star',
        //   text: 'Favorite',
        //   handler: () => {
        //     console.log('Favorite clicked');
        //   }
        // },
        
        {
          text: 'Dismiss',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }
  async verificationToast(message: string, duration: number, positions, color: string) {
    const toast = await this.toastController.create({
      message,
      // duration,
      animated: true,
      color,
      position: positions,
      translucent: false,
      buttons: [
        {
          side: 'end',
          icon: 'mail-unread',
          text: ' Check Mail',
          handler: () => {
            // this.router.navigateByUrl('');
          }
        }
      ]
    });
    toast.present();
  }
  async loading(message,duration) {
    const loading = await this.loadingController.create({
      message: message,
      duration: duration,
      spinner: 'bubbles'
    });
    await loading.present();
  }
}

