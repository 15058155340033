import { TutorialGuard } from './guard/tutorial.guard';
import { AuthGuard } from './guard/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'registration',
    loadChildren: () => import('./registrationsystem/registrationpage/registrationpage.module').then( m => m.RegistrationpagePageModule)
  },   
  {
    path: 'createpost',
    loadChildren: () => import('./post/createpost/createpost.module').then( m => m.CreatepostPageModule)
  },
  {
    path: 'jobsearch',
    loadChildren: () => import('./job/jobsearch/jobsearch.module').then( m => m.JobsearchPageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./component/setting/setting.module').then( m => m.SettingPageModule)
  },

  {
    path: 'notifications/:id',
    loadChildren: () => import('./header/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'job/:id',
    loadChildren: () => import('./job/jobdetail/jobdetail.module').then( m => m.JobdetailPageModule)
  },
  {
    path: 'ref/:id',
    loadChildren: () => import('./job/joblist/joblist.module').then( m => m.JoblistPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./header/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'home/:id',
    loadChildren: () => import('./post/comment/comment.module').then( m => m.CommentPageModule)
  },
  {
    path: 'follow/:id',
    loadChildren: () => import('./pages/profile/follow/follow.module').then( m => m.FollowPageModule)
  },
  {
    path: 'rating',
    loadChildren: () => import('./pages/profile/rating/rating.module').then( m => m.RatingPageModule)
  },
  {
    path: 'pricelist',
    loadChildren: () => import('./payment/pricelist/pricelist.module').then( m => m.PricelistPageModule)
  },
  {
    path: 'subcription',
    loadChildren: () => import('./payment/subcription/subcription.module').then( m => m.SubcriptionPageModule)
  },
  {
    path: 'jobapplicants/:id',
    loadChildren: () => import('./job/applied-jobs/applied-jobs.module').then( m => m.AppliedJobsPageModule)
  },
  {
    path: 'feature-profile',
    loadChildren: () => import('./pages/profile/feature-profile/feature-profile.module').then( m => m.FeatureProfilePageModule)
  },
  {
    path: 'feature-job/:id',
    loadChildren: () => import('./job/feature-job/feature-job.module').then( m => m.FeatureJobPageModule)
  },
  {
    path: 'feature-event/:id',
    loadChildren: () => import('./programe/feature-event/feature-event.module').then( m => m.FeatureEventPageModule)
  },

  {
    path: 'editjob/:id',
    loadChildren: () => import('./job/editjob/editjob.module').then( m => m.EditjobPageModule)
  },
  {
    path: 'fund-account',
    loadChildren: () => import('./payment/fund-account/fund-account.module').then( m => m.FundAccountPageModule)
  },
  {
    path: 'fund-promo',
    loadChildren: () => import('./payment/fund-promo/fund-promo.module').then( m => m.FundPromoPageModule)
  },
  {
    path: 'editprofile/:id',
    loadChildren: () => import('./pages/profile/editprofile/editprofile.module').then( m => m.EditprofilePageModule)
  },
  {
    path: 'manageevent/:id',
    loadChildren: () => import('./programe/programevent/programevent.module').then( m => m.ProgrameventPageModule)
  },
  {
    path: 'eventlist',
    loadChildren: () => import('./programe/eventlist/eventlist.module').then( m => m.EventlistPageModule)
  },
  {
    path: 'locationsearch',
    loadChildren: () => import('./component/locationsearch/locationsearch.module').then( m => m.LocationsearchPageModule)
  },
  {
    path: 'refer',
    loadChildren: () => import('./component/refer/refer.module').then( m => m.ReferPageModule)
  },
  {
    path: 'otherprofile',
    loadChildren: () => import('./pages/profile/otherprofile/otherprofile.module').then( m => m.OtherprofilePageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./component/feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./component/logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./component/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'getstart',
    loadChildren: () => import('./component/getstart/getstart.module').then( m => m.GetstartPageModule)
  },
  {
    path: 'homepreference',
    loadChildren: () => import('./component/homepreference/homepreference.module').then( m => m.HomepreferencePageModule)
  },
  {
    path: 'passwordsetting',
    loadChildren: () => import('./component/passwordsetting/passwordsetting.module').then( m => m.PasswordsettingPageModule)
  },
 
  {
    path: 'search',
    loadChildren: () => import('./header/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'searchevent',
    loadChildren: () => import('./programe/searchevent/searchevent.module').then( m => m.SearcheventPageModule)
  },
  {
    path: 'searchuser',
    loadChildren: () => import('./pages/profile/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'nopromo',
    loadChildren: () => import('./component/nopromo/nopromo.module').then( m => m.NopromoPageModule)
  },
  {
    path: 'eventapplicants/:id',
    loadChildren: () => import('./programe/applied/applied.module').then( m => m.AppliedPageModule)
  },
  {
    path: 'event/:id',
    loadChildren: () => import('./programe/eventdetaile/eventdetaile.module').then( m => m.EventdetailePageModule)
  },
  {
    path: 'user/:username',
    loadChildren: () => import('./pages/profile/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'allfeaturedprofile/:id',
    loadChildren: () => import('./pages/profile/allfeaturedprofile/allfeaturedprofile.module').then( m => m.AllfeaturedprofilePageModule)
  },
  {
    path: 'allfeaturtjobs',
    loadChildren: () => import('./job/allfeaturtjobs/allfeaturtjobs.module').then( m => m.AllfeaturtjobsPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./errorpage/errorpage.module').then( m => m.ErrorpagePageModule)
  },
  {
    path: 'share',
    loadChildren: () => import('./component/share/share.module').then( m => m.SharePageModule)
  }, 
  {
    path: 'editprogram/:id',
    loadChildren: () => import('./programe/edit-event/edit-event.module').then( m => m.EditEventPageModule)
  },
  {
    path: 'photo/:id',
    loadChildren: () => import('./pages/profile/image-details/image-details.module').then( m => m.ImageDetailsPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./wecome/wecome.module').then( m => m.WecomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./auth/verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./auth/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'register-artisan',
    loadChildren: () => import('./auth/register-artisan/register-artisan.module').then( m => m.RegisterArtisanPageModule)
  },
  {
    path: 'register-business',
    loadChildren: () => import('./auth/register-business/register-business.module').then( m => m.RegisterBusinessPageModule)
  },
  {
    path: 'reset-password-link',
    loadChildren: () => import('./auth/reset-password-link/reset-password-link.module').then( m => m.ResetPasswordLinkPageModule)
  },
  {
    path: 'new-post',
    loadChildren: () => import('./pages/new-post/new-post.module').then( m => m.NewPostPageModule)
  },
  {
    path: 'make-post',
    loadChildren: () => import('./pages/make-post/make-post.module').then( m => m.MakePostPageModule)
  },
  {
    path: 'chats',
    loadChildren: () => import('./chats/chats.module').then( m => m.ChatsPageModule)
  },
  {
    path: 'chat-view',
    loadChildren: () => import('./chat-view/chat-view.module').then( m => m.ChatViewPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
