import { AuthService } from './../../services/auth.service';
import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-homepreference',
  templateUrl: './homepreference.page.html',
  styleUrls: ['./homepreference.page.scss'],
})
export class HomepreferencePage implements OnInit {
  Preferredskill = '';
  Preferredjob = '';

  categories = [
    'Photographer', 'Videographer', 'Make Up Artist', 'Model', ' Run way Modal', 'Usher', 'Bouncer/Security', ' Tailor/Fashion Designer',
    'Fashion Illustrator', 'Paint Artist', 'Mechanic', 'Plumber', 'Electrician', 'Satallite Engineer/Installer', 'Carpenter',
    'Mobile Device/Laptop Engineer', 'Wallpaper installer', ' Interior Decorator', 'Cake Designer & Pastries', 'Chef', 'MC/Comedian',
    'TV Host', 'DJ', 'Drivers', 'Music Artist', 'Actors', 'Actress', 'Movie Extras', 'Electronics Device repairer', 'AC Installer', 'Cleaner',
    'Nutritionist', 'Gym Instructor', 'Car Spray painter', 'Bike Messenger', 'Graphic Designer', 'Web Designer', 'Web Developer', 'Mobile App Designer',
    'UX Designer', 'Laundry/Dry Cleaner', 'Wedding Halls', 'OAP', 'Bolgger', 'Writer', 'Film Director', 'Waiter', 'Waitress', 'Generator repairer'
  ];

  constructor(private modalController: ModalController,  private userService: UserService, public  auth: AuthService) { }

  accountForm = new FormGroup({
    preferredjob: new FormControl('', []),
    preferredskill: new FormControl('', []),
  });

  get preferredjob() {
    return this.accountForm.get('preferredjob');
  }
  get preferredskill() {
    return this.accountForm.get('preferredskill');
  }

  ngOnInit() {
    this.auth.getAuthState().subscribe(
      currentuser => {
        if (currentuser) {
          this.userService.retrieveUserDocument(currentuser.uid).subscribe(
            user => {
              if (user) {
                this.Preferredjob = user.Preferredjob;
                this.Preferredskill = user.Preferredskill;
              }
            });
        } else {
          // this.router.navigateByUrl('auth');
        }
      });
  }

  update() {

    this.auth.updatePreference(
      this.Preferredjob,
       this.Preferredskill
        ).then(
      () => console.log('User details updated'));

  }
  close(){
    this.modalController.dismiss();
    }
}
