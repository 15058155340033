export class Post {
    postId: string;
    content?: string;
    image?: string;
    comment?: number;
    views?: number;
    likes?: [];
    post?: string;
    type?: string;
    shares?: number;
    author?: string;
    coordinates?: any;
    authorusername?: string;
    createdDate?: any;
    authorimage?: string;
    isFeatured?: boolean;
    authorId?: string;

    constructor() {
        this.content = '';
        this.image = '';
    }
}
