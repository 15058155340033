import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-createpost',
  templateUrl: './createpost.page.html',
  styleUrls: ['./createpost.page.scss'],
})
export class CreatepostPage implements OnInit {

  constructor() { }
   selected 
  ngOnInit() {
  }

  onclick(){
      this.selected = true
      console.log("this was clicked if you clike this agine it will log this to the conlos")
  }
}
