import { AuthService } from './auth.service';
import { HelperService } from './helper.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Application } from '../models/applications';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  isApplied(jobId, currentuid) {
    return this.db.collection<any>('/jobs/' + jobId + '/applicants', ref => ref.where('uid', '==', currentuid)).valueChanges()
  }

  constructor(private db: AngularFirestore, public router: Router, private message: HelperService, private auth: AuthService ) { }
  applyJob(application: Application) {
  const applicationData = JSON.parse(JSON.stringify(application));
  return this.db.collection('applications').add(applicationData).then(
    () => {
    this.message.messageToast('Job Applied Successfully', 2000, 'top', 'primary');
    this.auth.ApplyjobCount();
    }
    );
  }
  getAllApplicationsForJob(jobId: string): Observable<Application[]> {
  const applications = this.db.collection<Application>('applications',
  ref => ref.where('jobId', '==', jobId).orderBy('applicationDate', 'desc')).snapshotChanges().pipe(
  map(actions => {
  return actions.map(
  c => ({
    applicationId: c.payload.doc.id,
  ...c.payload.doc.data()
  }));
  }));
  return applications;
  }
  deleteAllApplicationForJob(jobId: string) {
  const applicationsToDelete = this.db.collection('applications', ref =>
 ref.where('jobId', '==', jobId)).snapshotChanges();
  applicationsToDelete.forEach(
  applicationList => {
  applicationList.forEach(application => {
  this.db.doc('applications/' + application.payload.doc.id).delete();
  });
  }
  );
  }
  deleteSingleApplication(applicationId: string) {
  return this.db.doc('applications/' + applicationId).delete();
  }
}
