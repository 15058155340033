import { PlaceSearchDirective } from './place-search.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EditjobPageRoutingModule } from './editjob-routing.module';

import { EditjobPage } from './editjob.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    EditjobPageRoutingModule
  ],
  declarations: [EditjobPage, PlaceSearchDirective]
})
export class EditjobPageModule {}
