import { Injectable } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class IonhelperService {

  constructor( 
    private loadingController: LoadingController,
    private modalController: ModalController) { }

  async presentModal(page,input ,style) {
    const modal = await this.modalController.create({
    component: page,
    componentProps: input,
    cssClass: style,

    });
    await modal.present();
  }

 showImgae(imagepath){
    // return this.photoViewer.show(imagepath);
  }
  async presentLoading(message, duration) {
    const loading = await this.loadingController.create({
      message,
      duration,
      spinner: 'bubbles'
    });
    await loading.present();
  }
}
