import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-refer',
  templateUrl: './refer.page.html',
  styleUrls: ['./refer.page.scss'],
})
export class ReferPage implements OnInit {

  text: string='XU3892NDJ299/SDK29938MSLXL9002MMK'
  imgurl:string= 'https://cdn.pixabay.com/photo/2019/12/26/05/10/pink-4719682_960_720.jpg'
  link: string='https://link.medium.com/JA4amAHFJ5'

  constructor(private modalController: ModalController, private socialSharing: SocialSharing) { }

  ngOnInit() {
  }
  close(){
    this.modalController.dismiss()
  }

  ShareWhatsapp(){
    this.socialSharing.share(this.text, this.imgurl, this.link);
  }

  ShareFacebook(){
    this.socialSharing.shareViaFacebookWithPasteMessageHint(this.text, this.imgurl, null /* url */, 'Copia Pega!')
  }

  SendEmail(){
    this.socialSharing.shareViaEmail('text', 'subject', ['email@address.com'])
  }

  SendTwitter(){
    this.socialSharing.shareViaTwitter(this.text, this.imgurl, this.link);
  }

  SendInstagram(){
    this.socialSharing.shareViaInstagram(this.text, this.imgurl);
  }

}
