import { PostsService } from './../../../services/posts.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute } from "@angular/router";

@Injectable()
export class ImageDetailsResolver implements Resolve<any> {

  constructor(public firebaseService: PostsService) { }

  resolve(route: ActivatedRouteSnapshot,) {

    return new Promise((resolve, reject) => {
      let imageId = route.paramMap.get('id');
      this.firebaseService.getImage(imageId)
      .subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }
}
