export class Job {
    title: string;
    Applications: number;
    description: string;
    country: string;
    state: string;
    type: string;
    address: string;
    localgovt: string;
    category: string;
    streetnumber?: string;
    image: string;
    gender: string;
    route: string;
    coordinates: any;
    city: string;
    deadline: any;
    experience: string;
    companyname: string;
    salary: string;
    currency: string;
    views: number;
    qualification: string;
    aboutemployer: string;
    instagram: string;
    facebook: string;
    twitter: string;
    googleplus: string;
    createdDate: any;
    authorimage: string;
    isFeatured: boolean;
    authorId: string;

    constructor() {
        this.title = '';
        this.country = '';
        this.address = '';
        this.currency = '';
        this.state = '';
        this.localgovt = '';
        this.experience = '';
        this.category = '';
        this.salary = '';
        this.image = '';
        this.description = '';
        this.deadline = '';
    }
}

