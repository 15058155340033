import { IonhelperService } from './../../services/ionhelper.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { HelperService } from './../../services/helper.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from './../../services/user.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { PostsService } from './../../services/posts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Event } from '../../models/events';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import * as geofirex from 'geofirex';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.page.html',
  styleUrls: ['./edit-event.page.scss'],
})
export class EditEventPage implements OnInit {

  isSubmitted = false;
  lat;
  lng;

  dP;
  dN;
  mail;
  userid;
  image;
  eventData = new Event();
  eventId;
  private unsubscribe$ = new Subject<void>();
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  editprogramForm: FormGroup;
  submitType: string;
  formType: string;
  dataPostedUrl: string;
  isNewForm: Observable<boolean>;
  isLoading = false;
  streetNumber = '';
  route = '';
  city = '';
  state = '';
  zipcode = '';
  country = '';

  constructor(
    private ion: IonhelperService,
    private postService: PostsService,
    private storage: AngularFireStorage,
    private router: Router,
    private userService: UserService,
    private auth: AuthService,
    private message: HelperService,
    private formBuilder: FormBuilder,
    private zone: NgZone,
    private routerr: ActivatedRoute,
    private db: AngularFirestore,
  ) { 
    if (this.routerr.snapshot.params['id']) {
      this.eventId = this.routerr.snapshot.paramMap.get('id');
      }

    this.editform();
  }

  editform() {
    this.editprogramForm = this.formBuilder.group({
      title: [this.eventData.title, Validators.required],
      description: [this.eventData.description, Validators.required],
      fee: [this.eventData.fee, Validators.required],
      time: [this.eventData.time,  Validators.required],
      startdate: [this.eventData.startdate, Validators.required],
      finishdate: [this.eventData.finishdate, Validators.required],
      streetnumber: [this.eventData.streetnumber],
      route: [this.eventData.route],
      city: [this.eventData.city],
      state: [this.eventData.state],
      country: [this.eventData.country],
      searchAddress: ['', Validators.required]
    });
  }

  

  ngOnInit() {
    this.getCurrentUser();
    this.postService.getEventbyId(this.eventId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
    (result: Event) => {
    this.eventData = result;
    }
    );
  }

  onSubmit(editprogramForm){

    if(!editprogramForm.valid ) {
      this.isSubmitted = true;
      this.message.validationToast('Please Fill All Required Fields', 2000, 'top', 'danger');
    } else {
      this.updateEventnew(editprogramForm)
    .then(
      res => {
        this.message.messageToast('Event Updated Successfully', 2000, 'top', 'primary');
        this.router.navigate(['/home']);
      }
    )
    }
  }

  updateEventnew(editprogramForm){
    const geo = geofirex.init(firebase);
    const position = geo.point(this.lat, this.lng);
    return this.db.doc('feed/' + this.eventId).update({
      title: editprogramForm.value.title,
      description: editprogramForm.value.description,
      fee: editprogramForm.value.fee,
      time: editprogramForm.value.time,
      startdate: editprogramForm.value.startdate,
      finishdate: editprogramForm.value.finishdate,
      author: this.eventData.author,
      coordinates: position,
      location: editprogramForm.value.searchAddress,
      streetnumber: this.streetNumber || null,
      route: this.route || null,
      city: this.city,
      state: this.state,
      country: this.country,
      views: this.eventData.views,
      isFeatured: this.eventData.isFeatured,
      authorId: this.eventData.authorId,
      createdDate: this.eventData.createdDate,
      authorimage: this.eventData.authorimage,
      image: this.image || this.eventData.image
    });
  }

  setPostFormData(postFormData) {
    this.eventData.title = postFormData.title;
    this.eventData.description = postFormData.description;
  }

  getCurrentUser() {
    this.auth.getAuthState().subscribe(
      user => {
        if (user) {
          this.userService.retrieveUserDocument(user.uid).subscribe(
            userDoc => {
              if (userDoc) {
                this.dP = userDoc.displayPicture;
                this.dN = userDoc.displayName;
                this.mail = userDoc.email;
                this.userid = userDoc.uid;
              }
            });
        } else {

        }
    });
  }

  setAddressFromMap(addressObj) {
    this.zone.run(() => {
      this.streetNumber = addressObj.street_number;
      this.route = addressObj.route;
      this.city = addressObj.locality;
      this.state = addressObj.admin_area_1;
      this.zipcode = addressObj.postal_code;
      this.country = addressObj.country;
    });

    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': this.route + this.route + this.city }, (results, status) => {
          this.zone.run(() => {
            this.lat = results[0].geometry.location.lat();
            this.lng = results[0].geometry.location.lng();
            console.log(this.lat, this.lng);
          });
      });
  }

  saveEventPost() {
    if (this.eventId) {
      this.postService.updateEvent(this.eventId, this.eventData).then(
        () => {
          this.router.navigate(['/']);
        }
      );
    } else {
      this.eventData.authorId = this.userid;
      this.eventData.createdDate = Date.now();
      this.eventData.image = this.image;
      this.eventData.views = 0;
      this.eventData.author = this.dN;
      this.eventData.authorimage = this.dP;
    }
  }

  // onSubmit(value){
  //   this.postService.createEvent(value, this.image).then(
  //     () => {
  //       this.message.messageToast('Event Posted Successfully', 2000, 'top', 'primary');
  //       this.router.navigate(['/home']);
  //     }
  //   );
  // }

  uploadEventImage(event) {
    const file = event.target.files[0];
    const path = `eventposts/${file.name}`;
    if ( file.type.split('/')[0] !== 'image') {
      return alert('Only Image Files');
    } else {
      this.ion.presentLoading('Uploading Image', 4000)
      const task = this.storage.upload(path, file);
      const ref = this.storage.ref(path);
      this.uploadPercent = task.percentageChanges();
      console.log('Image uploaded!');
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL();
          this.downloadURL.subscribe(url => (this.image = url));
        })
      )
      .subscribe();
    }
  }

}
