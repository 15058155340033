import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component } from '@angular/core';
import * as AOS from 'aos';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';




@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private router: Router,
    private storage: Storage,
    private splashScreen: SplashScreen,
    public afAuth: AngularFireAuth,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }
  ngOnInit(){
     AOS.init();
    }

  initializeApp() {
    this.platform.ready().then(() => {
      this.afAuth.authState.subscribe(
        user => {
          if (user) {
            this.statusBar.overlaysWebView(false);
            this.statusBar.backgroundColorByHexString('#0074e4');
            // this.router.navigateByUrl('/home');
            this.splashScreen.hide();
          } else {
            this.statusBar.overlaysWebView(false);
            this.statusBar.backgroundColorByHexString('#0074e4');
            // this.router.navigateByUrl('/registration');
            this.splashScreen.hide();
          }
        });
    });
  }
}
