import { LogoutPageModule } from './../../component/logout/logout.module';
import { FeedbackPageModule } from './../../component/feedback/feedback.module';
import { SettingPageModule } from './../../component/setting/setting.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MorePageRoutingModule } from './more-routing.module';

import { MorePage } from './more.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MorePageRoutingModule,
    SettingPageModule,
    FeedbackPageModule,
    LogoutPageModule
  ],
  declarations: [MorePage]
})
export class MorePageModule {}
