import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AngularFirestore } from '@angular/fire/firestore';
import { HelperService } from './../../services/helper.service';
import { UserService } from './../../services/user.service';
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { PostsService } from './../../services/posts.service';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Job } from '../../models/jobs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';
import * as firebase from 'firebase/app';
import * as geofirex from 'geofirex';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit {

  isSubmitted = false;

  free;
  bronze;
  silver;
  gold;
  lat;
  lng;
  jobForm: FormGroup;
  about;
  dP;
  dN;
  mail;
  userid;
  image: string = null;
  fb;
  ig;
  tw;
  gp;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  jobData = new Job();
  jobId;
  private unsubscribe$ = new Subject<void>();

  categories = [
    'Photographer', 'Videographer', 'Hair Stylist', 'Make Up Artist', 'Model', 'Wedding & Event planner', 'Branding & Print',
    ' Run way Model', 'Usher', 'Bouncer/Security', ' Tailor/Fashion Designer',
    'Fashion Illustrator', 'Paint Artist', 'Mechanic', 'Plumber', 'Electrician', 'Satallite Engineer/Installer', 'Carpenter',
    'Mobile Device/Laptop Engineer', 'Wallpaper installer', ' Interior Decorator', 'Cake Designer & Pastries', 'Chef', 'MC/Comedian',
    'TV Host', 'DJ', 'Drivers', 'Music Artist', 'Actors', 'Dancer', 'Actress', 'Movie Extras',
     'Electronics Device repairer', 'AC Installer', 'Cleaner',
    'Nutritionist', 'Gym Instructor', 'Car Spray painter', 'Bike Messenger', 'Graphic Designer',
     'Web Designer', 'Web Developer', 'Shoemaker', 'Mobile App Designer',
    'UX Designer', 'Laundry/Dry Cleaner', 'Wedding Halls', 'OAP',
    'Blogger', 'Digital Marketer', 'Writer', 'Film Director', 'Waiter', 'Waitress', 'Generator repairer'
  ];

  experience =['Ammature', 'Intermediate', 'Professional'];
  types = ['Part-time', 'Full-time'];
  genders = ['Male', 'Female', 'Any'];
  quals = [ 'Ph.D', 'MSc.', 'BSc.', 'A-level.', 'O-level', 'Others'];
  curs = [
    '₦', '$', '£', '€', '#GH₵', 'ZAR',
  ];

  streetNumber?: string = '';
  route?: string = '';
  city?: string = '';
  state?: string = '';
  zipcode?: string = '';
  country?: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private geolocation: Geolocation,
    private router: Router,
    private zone: NgZone,
    private db: AngularFirestore,
    private message: HelperService,
    private postService: PostsService,
    private storage: AngularFireStorage,
    private userService: UserService,
    private auth: AuthService,
    private loadingController: LoadingController
  ) {
    this.geolocation.getCurrentPosition().then((resp) => {
      // console.log(resp.coords.latitude, resp.coords.longitude)
      this.lat = resp.coords.latitude;
      this.lng = resp.coords.longitude;
     }).catch((error) => {
       console.log('Error getting location', error);
     });
  }


  ngOnInit() {
    this.createForm();
    this.getCurrentUser();
    if (this.jobId) {
      this.postService.getPostbyId(this.jobId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            this.setPostFormData(result);
          }
        );
    }
  }

  createForm() {
    this.jobForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
      experience: ['', Validators.required],
      type: ['', Validators.required],
      qualification: ['', Validators.required],
      gender: ['', Validators.required],
      currency: ['', Validators.required],
      salary: ['', Validators.required],
      deadline: ['', Validators.required],
      searchAddress: ['', Validators.required],
      streetnumber: [this.streetNumber],
      route: [this.route],
      city: [this.city],
      state: [this.state],
      country: [this.country]
    });
  }

  setAddressFromMap(addressObj) {
    this.zone.run(() => {
      this.streetNumber = addressObj.street_number;
      this.route = addressObj.route;
      this.city = addressObj.locality;
      this.state = addressObj.admin_area_1;
      this.zipcode = addressObj.postal_code;
      this.country = addressObj.country;
    });
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': this.route + this.route + this.city }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          this.zone.run(() => {
            this.lat = results[0].geometry.location.lat();
            this.lng = results[0].geometry.location.lng();
            console.log(this.lat, this.lng);
          })
        } else {
          alert('Error - ' + results + ' & Status - ' + status);
        }
      });
  }

  getCurrentUser() {
    this.auth.getAuthState().subscribe(
      user => {
        if (user) {
          this.userService.retrieveUserDocument(user.uid).subscribe(
            userDoc => {
              if (userDoc) {
                this.dP = userDoc.displayPicture;
                this.dN = userDoc.displayName;
                this.mail = userDoc.email;
                this.userid = userDoc.uid;
                this.about = userDoc.Bio;
                this.fb = userDoc.Facebook;
                this.ig = userDoc.Instagram;
                this.tw = userDoc.Twitter;
                this.gp = userDoc.Googleplus;
                this.free = userDoc.subscriberFree;
                this.bronze = userDoc.subscriberBronze;
                this.silver = userDoc.subscriberSilver;
                this.gold = userDoc.subscriberGold;
              }
            });
        } else {

        }
    });
  }

  setPostFormData(postFormData) {
    this.jobData.title = postFormData.title;
    this.jobData.description = postFormData.description;
  }

  

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit(jobForm) {
    if (!jobForm.valid ) {
      this.isSubmitted = true;
      this.message.validationToast('Please Fill All Required Fields', 2000, 'top', 'danger');
    } else {
      this.createJob(jobForm)
    .then(
      res => {
        // this.resetFields();
        this.router.navigate(['/home']);
      }
    )}
  }

  createJob(jobForm) {
    const geo = geofirex.init(firebase);
    const position = geo.point(this.lat, this.lng);
    return this.db.collection('jobs').add({
      title: jobForm.value.title,
      description: jobForm.value.description,
      category: jobForm.value.category,
      experience: jobForm.value.experience,
      type: jobForm.value.type,
      qualification: jobForm.value.qualification,
      gender: jobForm.value.gender,
      currency: jobForm.value.currency,
      salary: jobForm.value.salary,
      deadline: jobForm.value.deadline,
      streetnumber: this.streetNumber || null,
      route: this.route,
      country: this.country,
      city: this.city,
      state: this.state,
      image: this.image,
      companyname: this.dN,
      coordinates: position,
      views: 0,
      Applications: 0,
      aboutemployer: this.about,
      instagram: this.ig,
      facebook: this.fb,
      twitter: this.tw,
      googleplus: this.gp,
      createdDate: Date.now(),
      authorimage: this.dP,
      isFeatured: false,
      authorId: this.userid
    }).then(() => this.auth.ApplyPostCount() && this.message.messageToast('Job Posted Successfully', 2000, 'top', 'primary'));
  }
async uploadImage(event) { 
    const loading = await this.loadingController.create({
      message: 'Uploading Image',
      duration: 5000,
      spinner: 'bubbles'
    });
    const file = event.target.files[0];
    const path = `jobimages/${file.name}`;
    if ( file.type.split('/')[0] !== 'image') {
      return alert('Only Image Files');
    } else {
      await loading.present();
      const task = this.storage.upload(path, file);
      const ref = this.storage.ref(path);
      this.uploadPercent = task.percentageChanges();
      console.log('Image uploaded!');
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL();
          this.downloadURL.subscribe(url => (this.image = url));
        })
      )
      .subscribe();
    }
  }
}
