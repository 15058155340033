import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HelperService } from './../../services/helper.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from './../../services/user.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PostsService } from './../../services/posts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Post } from '../../models/posts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as firebase from 'firebase/app';
import * as geofirex from 'geofirex';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit, OnDestroy {
  blogForm: FormGroup;
  lat;
  lng;
  dP;
  dN;
  uN;
  mail;
  userid;
  image: string = null;
  postData = new Post();
  postId;
  private unsubscribe$ = new Subject<void>();
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  constructor(
    private route: ActivatedRoute,
    private postService: PostsService,
    private storage: AngularFireStorage,
    private geolocation: Geolocation,
    private router: Router,
    private message: HelperService,
    private formBuilder: FormBuilder,
    private db: AngularFirestore,
    private userService: UserService,
    private auth: AuthService,
    private loadingController: LoadingController
  ) {
    if (this.route.snapshot.params['id']) {
      this.postId = this.route.snapshot.paramMap.get('id');
    }
  }

  ngOnInit() {
    this.geolocation.getCurrentPosition().then((resp) => {
      // console.log(resp.coords.latitude, resp.coords.longitude)
      this.lat = resp.coords.latitude;
      this.lng = resp.coords.longitude;
     }).catch((error) => {
       console.log('Error getting location', error);
     });
    this.createForm();
    this.getCurrentUser();
    if (this.postId) {
      this.postService.getPostbyId(this.postId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            this.setPostFormData(result);
          }
        );
    }
  }

  createForm() {
    this.blogForm = this.formBuilder.group({
      content: ['']
    });
  }

  onSubmit(blogForm) {
    this.createPost(blogForm);
  }

  createPost(blogForm) {
    const geo = geofirex.init(firebase);
    const position = geo.point(this.lat, this.lng);
    return this.db.collection('feed').add({
      post: blogForm.value.content,
      authorId: this.userid,
      createdDate: Date.now(),
      image: this.image,
      author: this.dN,
      type: 'Blog',
      coordinates: position,
      authorusername: this.uN,
      authorimage: this.dP,
      views: 0,
      likes: [],
      comment: 0,
      shares : 0
    }).then(() => {
      this.resetFields();
      this.message.messageToast('Posted Successfully', 2000, 'top', 'primary')
      this.router.navigate(['/home']);
    });
  }

  resetFields() {
    this.blogForm = this.formBuilder.group({
      title: new FormControl(''),
    });
  }


  getCurrentUser() {
    this.auth.getAuthState().subscribe(
      user => {
        if (user) {
          this.userService.retrieveUserDocument(user.uid).subscribe(
            userDoc => {
              if (userDoc) {
                this.dP = userDoc.displayPicture;
                this.dN = userDoc.displayName;
                this.mail = userDoc.email;
                this.userid = userDoc.uid;
                this.uN = userDoc.userName;
              }
            });
        } else {

        }
    });
  }
  setPostFormData(postFormData) {
    this.postData.content = postFormData.content;
  }
  saveBlogPost() {
    if (this.postId) {
      this.postService.updatePost(this.postId, this.postData).then(
        () => {
          this.router.navigate(['/']);
        }
      );
    } else {
      this.postData.authorId = this.userid;
      this.postData.createdDate = Date.now();
      this.postData.image = this.image;
      this.postData.author = this.dN;
      this.postData.type = 'Blog';
      this.postData.authorusername = this.uN;
      this.postData.authorimage = this.dP;
      this.postData.views = 0;
      this.postData.likes = [];
      this.postData.comment = 0;
      this.postData.shares = 0;
      this.postService.createPost(this.postData).then(
        () => {
          this.router.navigate(['/home']);
        }
      );
    }
  }

  cancel() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

async  uploadPostImage(event) {
    const loading = await this.loadingController.create({
        message: 'Uploading Image',
        duration: 4000,
        spinner: 'bubbles'
      });
    const file = event.target.files[0];
    const path = `profileposts/${file.name}`;
    if ( file.type.split('/')[0] !== 'image') {
      return alert('Only Image Files');
    } else {

     await loading.present();
     const task = this.storage.upload(path, file);
     const ref = this.storage.ref(path);
     this.uploadPercent = task.percentageChanges();
     console.log('Image uploaded!');
     task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL();
          this.downloadURL.subscribe(url => (this.image = url));
        })
      )
      .subscribe();
    }
  }

}
