import { HelperService } from './helper.service';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Post } from '../models/posts';
import { Job } from '../models/jobs';
import { Comment } from '../models/comments';
import { Event } from '../models/events';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  postReference: AngularFirestoreDocument;
  sub;
  about;
  dP;
  dN;
  mail;
  userid;
  fb;
  ig;
  tw;
  gp;
  yes;

  readonly path = 'jobs';
  readonly pathprofilepost = 'profile-posts';
  readonly paths = 'posts';
  readonly bpath = 'feed';
  readonly epath = 'feed';
  readonly cpath = 'comments';

  constructor(private db: AngularFirestore, private userService: UserService,
              private auth: AuthService) {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.auth.getAuthState().subscribe(
      user => {
        if (user) {
          this.userService.retrieveUserDocument(user.uid).subscribe(
            userDoc => {
              if (userDoc) {
                this.dP = userDoc.displayPicture;
                this.dN = userDoc.displayName;
                this.mail = userDoc.email;
                this.userid = userDoc.uid;
                this.about = userDoc.Bio;
                this.fb = userDoc.Facebook;
                this.ig = userDoc.Instagram;
                this.tw = userDoc.Twitter;
                this.gp = userDoc.Googleplus;
              }
            });
        } else {
        }
    });
  }

  getImage(imageKey){
    return this.db.collection('galleryimages').doc(imageKey).snapshotChanges();
  }

  getEvent(eventKey){
    return this.db.collection('events').doc(eventKey).snapshotChanges();
  }
  getComment(commentKey) {
    return this.db.collection('comments').doc(commentKey).snapshotChanges();
  }

  createPost(post: Post) {
    const postData = JSON.parse(JSON.stringify(post));
    return this.db.collection('blogs').add(postData);
  }
  
  createJob(value, image) {
    return this.db.collection('jobs').add({
      title: value.title,
      description: value.description,
      category: value.category,
      experience: value.experience,
      type: value.type,
      qualification: value.qualification,
      gender: value.gender,
      currency: value.currency,
      salary: value.salary,
      deadline: value.deadline,
      location: value.location,
      streetnumber: value.streetnumber,
      route: value.route,
      city: value.city,
      state: value.state,
      country: value.country,
      views: 0,
      isFeatured: false,
      aboutemployer: this.about,
      companyname: this.dN,
      authorId: this.userid,
      createdDate: Date.now(),
      image
    });

  }
  createEvent(value, image) {
    return this.db.collection('events').add({
      title: value.title,
      description: value.description,
      fee: value.fee,
      time: value.time,
      startdate: value.startdate,
      finishdate: value.finishdate,
      author: this.dN,
      location: value.searchAddress,
      streetnumber: value.streetnumber,
      route: value.route,
      city: value.city,
      state: value.state,
      country: value.country,
      views: 0,
      isFeatured: false,
      authorId: this.userid,
      createdDate: Date.now(),
      authorimage: this.dP,
      image
    });
  }

  getAllPosts(): Observable<Post[]> {
    const blogs = this.db.collection<Post>('blogs', ref => ref.orderBy('createdDate', 'desc')).snapshotChanges().pipe(
      map(actions => {
        return actions.map(
          c => ({
            postId: c.payload.doc.id,
            ...c.payload.doc.data()
          }));
      }));
    return blogs;
  }
  
  getAllJobs() {
    const jobs = this.db.collection('jobs', ref => ref.orderBy('createdDate', 'desc')).snapshotChanges();
    return jobs;
  }
  getMostviewedJobs() {
    const jobs = this.db.collection('jobs', ref => ref.where('deadline', '>=', Date.now()).orderBy('deadline', 'desc')).snapshotChanges();
    return jobs;
  }

  getEvents() {
    return this.db.collection('feed', ref => ref.where('type', '==', 'Event').orderBy('createdDate', 'desc')).snapshotChanges();
  }
  getSearchJobs() {
    return this.db.collection('jobs').snapshotChanges();
  }

  searchUsers(searchValue) {
    return this.db.collection('events',ref => ref.where('title', '>=', searchValue)
      .where('title', '<=', searchValue + '\uf8ff'))
      .snapshotChanges();
  }
  searchJobs(searchValue){
    return this.db.collection('jobs',ref => ref.where('title', '>=', searchValue)
      .where('title', '<=', searchValue + '\uf8ff'))
      .snapshotChanges();
  }

  getAllFeaturedJobs() {
    const jobs = this.db.collection('jobs', ref =>
   ref.where('isFeatured', '==', true).orderBy('Datefeatured', 'desc'))
    .snapshotChanges();
    return jobs;
    }
  getAllmyEvents(id): Observable<Event[]> {
    const events = this.db.collection<Event>('feed', ref => ref.where('authorId', '==', id)
    .where('type', '==', 'Event').orderBy('createdDate', 'desc'))
    .snapshotChanges().pipe(
      map(actions => {
        return actions.map(
          c => ({
            eventId: c.payload.doc.id,
            ...c.payload.doc.data()
          }));
      }));
    return events;
  }
  getAllEvents(): Observable<Event[]> {
    const events = this.db.collection<Event>('events', ref => ref.orderBy('createdDate', 'desc'))
    .snapshotChanges().pipe(
      map(actions => {
        return actions.map(
          c => ({
            eventId: c.payload.doc.id,
            ...c.payload.doc.data()
          }));
      }));
    return events;
  }
  getAllFeaturedEvents(): Observable<Event[]> {
    const events = this.db.collection<Event>('events', ref => ref.where('isFeatured', '==', true).orderBy('Datefeatured', 'desc'))
    .snapshotChanges().pipe(
      map(actions => {
        return actions.map(
          c => ({
            eventId: c.payload.doc.id,
            ...c.payload.doc.data()
          }));
      }));
    return events;
  }

  updateEventnew(eventKey, value){
    return this.db.collection('events').doc(eventKey).set(value);
  }

  getPostbyId(id: string) {
    const blogDetails = this.db.doc('feed/' + id).valueChanges();
    return blogDetails;
  }
  getJobbyId(id: string): Observable<Job> {
    const jobDetails = this.db.doc<Job>('jobs/' + id).valueChanges();
    return jobDetails;
  }
  getEventbyId(id: string): Observable<Event> {
    const eventDetails = this.db.doc<Event>('feed/' + id).valueChanges();
    return eventDetails;
  }

  updatePost(postId: string, post: Post) {
    const putData = JSON.parse(JSON.stringify(post));
    return this.db.doc('blogs/' + postId).update(putData);
  }
  updateJob(jobId: string, job: Job) {
    const putData = JSON.parse(JSON.stringify(job));
    return this.db.doc('jobs/' + jobId).update(putData);
  }
  updateEvent(eventId: string, event: Event) {
    const putData = JSON.parse(JSON.stringify(event));
    return this.db.doc('events/' + eventId).update(putData);
  }

 
  deleteJob(jobId: string) {
    return this.db.doc('jobs/' + jobId).delete();
  }
  deleteEvent(eventId: string) {
    return this.db.doc('feed/' + eventId).delete();
  }


  viewJob(id: string, data: Partial<Job>): Promise<void> {
    return this.db.doc<Job>(`${this.path}/${id}`).update(data);
  }
  FeatureJob(id: string, data: Partial<Job>): Promise<void> {
    return this.db.doc<Job>(`${this.path}/${id}`).update(data);
  }
  FeatureEvent(id: string, data: Partial<Event>): Promise<void> {
    return this.db.doc<Event>(`${this.epath}/${id}`).update(data);
  }
  viewPost(id: string, data: Partial<Post>): Promise<void> {
    return this.db.doc<Post>(`${this.bpath}/${id}`).update(data);
  }
  viewEvent(id: string, data: Partial<Event>): Promise<void> {
    return this.db.doc<Event>(`${this.epath}/${id}`).update(data);
  }

  // viewProfilePost(id: string, data: Partial<Ppost>): Promise<void> {
  //   return this.db.doc<Ppost>(`${this.pathprofilepost}/${id}`).update(data);
  // }

  LikePost(id: string, data: Partial<Post>): Promise<void> {
    return this.db.doc<Post>(`${this.bpath}/${id}`).update(data);
  }
  // LikeComment(id: string) {
  //   this.postReference = this.db.doc(`${this.cpath}/${id}`);
  //   this.sub = this.postReference.valueChanges().subscribe(val => {
  //   this.yes = val.comment.includes(this.userid);
  //    });
  //   if (this.yes) {
  //     this.postReference.update({
  //       likes: firestore.FieldValue.arrayRemove(this.userid),
       
  //     }).then(() =>  this.message.messageToast('Disliked', 2000, 'top', 'primary'));
  //   } else if (!this.yes) {
  //     this.postReference.update({
  //       likes: firestore.FieldValue.arrayUnion(this.userid)
  //     }).then(() => this.message.messageToast('Liked', 2000, 'top', 'primary'));
      
  //   }

  //   // return this.db.doc<Comment>(`${this.cpath}/${id}`);
  // }
  disLikeComment(id: string, data: Partial<Comment>): Promise<void> {
    return this.db.doc<Comment>(`${this.cpath}/${id}`).update(data);
  }
}
