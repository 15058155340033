export class Event {
    eventId: string;
    title: string;
    description: string;
    fee: string;
    location: string;
    views: number;
    time: any;
    startdate: any;
    finishdate: any;
    Applications: number;
    image: string;
    author: string;
    createdDate: any;
    coordinates: any;
    authorimage: string;
    username: string;
    isFeatured: boolean;
    authorId: string;
    city: string;
    country: string;
    streetnumber: string;
    route: string;
    state: string;


    constructor() {
        this.title = '';
        this.description = '';
        this.image = '';
        this.fee = '';
        this.location = '';
    }
}
