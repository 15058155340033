import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { AlertController, ActionSheetController } from '@ionic/angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.page.html',
  styleUrls: ['./logout.page.scss'],
})
export class LogoutPage implements OnInit {

  constructor( 
    private modalController: ModalController,
    private alertController: AlertController,
    private actionSheetController: ActionSheetController,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
  }
  close(){
   this.modalController.dismiss()
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Albums',
      buttons: [{
        text: 'Delete',
        role: 'destructive',
        icon: 'trash',
        handler: () => {
          console.log('Delete clicked');
        }
      }, {
        text: 'Share',
        icon: 'share',
        handler: () => {
          console.log('Share clicked');
        }
      }, {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
  
    await actionSheet.present();
  }

  async feedback() {
    const alert = await this.alertController.create({
      header: 'Feedback and complaints',
      message: 'Kindly enter your feedback or complaints here.',
      inputs: [
        {
          name: 'name1',
          type: 'text',
          placeholder: 'Write a message...'
        },

  
      ],
      buttons: [
        {
          text: 'Send',
          role: 'submit',
          cssClass: 'btn btn-primary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }
      ]
    });

    await alert.present();
  }

async logout() {
  this.modalController.dismiss();
  this.authService.notOnline();
}
// async logout() {
//   this.modalController.dismiss();
//   const alert = await this.alertController.create({
//     header: 'logout',
//     message: 'are you sure you want to logout',
//     buttons: [
//       {
//         text: 'Cancel',
//         role: 'cancel',
//         cssClass: 'secondary btn btno',
//         handler: (blah) => {
//           console.log('Confirm Cancel: blah');
//         }
//       }, {
//         cssClass:'btn btn-outline-secondary text-center',
//         text: 'Yes',
//         handler: () => {
//           this.authService.doLogout()
//           console.log('Confirm Okay');
//         }
//       }
//     ],

//   });

//   await alert.present();
// }
}
