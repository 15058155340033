import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
//import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

@Injectable()
export class UserService {

  constructor(
    public auth: AuthService,
    private afs: AngularFirestore,
    public router: Router
  ) { }

  retrieveUserDocument(uid) {
    return this.afs.doc<any>('users/' + uid).valueChanges();
  }

  retrieveUserDocumentFromUsername(username) {
    return this.afs.collection('users', ref => ref.where('userName', '==', username)).valueChanges();
  }
  retrieveUserDocumentFromID(uid) {
    return this.afs.doc<any>('users/' + uid).valueChanges();
  }

  getUserGroups(uid) {
    return this.afs.collection('users/' + uid + '/groups', ref => ref.orderBy('last', 'desc')).valueChanges();
  }

  getFeaturedUsers() {
    return this.afs.collection('users', ref => ref.where('isFeatured', '==', true).orderBy('Datefeatured', 'desc')).valueChanges();
  }
  getNormalUsers() {
    return this.afs.collection('users', ref => ref.orderBy('Online', 'desc')).valueChanges();
  }
}
