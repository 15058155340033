import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { UserService } from './../../services/user.service';
import { FeedbackPage } from './../../component/feedback/feedback.page';
import { SettingPage } from './../../component/setting/setting.page';
import { IonhelperService } from './../../helper/ionhelper.service';
import { Component, OnInit } from '@angular/core';
import { LogoutPage } from 'src/app/component/logout/logout.page';

@Component({
  selector: 'app-more',
  templateUrl: './more.page.html',
  styleUrls: ['./more.page.scss'],
})
export class MorePage implements OnInit {

  userid;
  uid;
  unreadlist;
  unreadlistlenght;

  constructor( 
    private ion: IonhelperService,
    public auth: AuthService,
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private router: Router,
    private userService: UserService ) { 
      this.uid = this.route.snapshot.paramMap.get('id');
    }

  ngOnInit() {
    this.getCurrentUser();
    this.getAllUnread();
  }

  viewterms() {
    window.location.href = "https://myskillconnect.com/";
  }

  viewPolicy() {
    window.location.href = "https://myskillconnect.com/privacy-policy";
  }

  getAllUnread() {
    this.getUnread().subscribe(result => {
    this.unreadlist = result;
    this.unreadlistlenght = result.length;
    });
  }

  getUnread() {
    const unread = this.db.collection('notifications', ref =>
    ref.where('nid', '==', this.uid).where('type', '==', 'chat')).snapshotChanges();
    return unread;
  }

  getCurrentUser() {
    this.auth.getAuthState().subscribe(
      user => {
        if (user) {
          this.userService.retrieveUserDocument(user.uid).subscribe(
            userDoc => {
              if (userDoc) {
                // this.displayName = userDoc.displayName;
                // this.userName = userDoc.userName;
                // this.wb = userDoc.Walletballance;
                // this.photoURL = userDoc.photoURL;
                // this.location = userDoc.Location;
                // this.online = userDoc.Online;
                this.userid = userDoc.uid;
                // this.free = userDoc.subscriberFree;
                // this.bronze = userDoc.subscriberBronze;
                // this.silver = userDoc.subscriberSilver;
                // this.gold = userDoc.subscriberGold;
                // this.sn = userDoc.shownotification;

                // Get pids from user feed


                // Get user's groups

              }
            });
        } else {

        }
    });
  }


  feedback() {
    this.ion.presentModal(FeedbackPage, 'user', 'bottom-model');
    }

 logout() {
   this.ion.presentModal(LogoutPage, 'user', 'bottom-model');
}
 
  setting() {
    this.ion.presentModal(SettingPage, '', 'my-custom-class');
    }
}
