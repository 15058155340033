import { EditprofilePageModule } from './pages/profile/editprofile/editprofile.module';
import { EditEventPageModule } from './programe/edit-event/edit-event.module';
import { EditjobPageModule } from './job/editjob/editjob.module';
import { CreatepostPageModule } from './post/createpost/createpost.module';
import { GplusService } from './services/gplus.service';
import { ApplicationService } from 'src/app/services/application.service';
import { ImageDetailsResolver } from './pages/profile/image-details/image-details.resolver';
import { LikeService } from './services/like.service';
import { PostsService } from './services/posts.service';
import { UserListComponent } from './pages/profile/user-list/user-list.component';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { FollowService } from './services/follow.service';
import { RatingPageModule } from './pages/profile/rating/rating.module';
import { HomepreferencePageModule } from './component/homepreference/homepreference.module';
import { MorePageModule } from './pages/more/more.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { IonicModule, IonicRouteStrategy, IonAvatar, ActionSheetController, PopoverController } from '@ionic/angular';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import * as firebase from 'firebase';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {NgPipesModule} from 'ngx-pipes';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PaymentPipe } from './payment.pipe';
import { AgmCoreModule } from '@agm/core';
import { ReferPageModule } from './component/refer/refer.module';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { MomentModule } from 'ngx-moment';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { AuthcService } from './chat-services/auth.service';
import { ChatsService } from './chat-services/chats.service';
import { UsercService } from './chat-services/user.service';
import { UtilService } from './chat-services/util.service';



firebase.initializeApp(environment.firebase);

@NgModule({
  declarations: [AppComponent, PaymentPipe],
  entryComponents: [ ],
  imports: [
    BrowserModule,
    NgPipesModule,
    NgxLinkifyjsModule.forRoot(),
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    }),
    NgxIonicImageViewerModule,
    IonicModule.forRoot({ mode: 'ios' }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB1iaUMrvn6YTnhp5LrNWtM-V2ec1cs5Ec'
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app
    AngularFirestoreModule, // imports firebase/firestore
    AngularFireAuthModule, // imports firebase/auth
    AngularFireStorageModule, // imports firebase/storage
    AngularFireDatabaseModule,
    ReferPageModule ,
    MorePageModule,
    CreatepostPageModule,
    EditEventPageModule,
    EditjobPageModule,
    EditprofilePageModule,
    HomepreferencePageModule ,
    RatingPageModule],
  providers: [
    PostsService,
    ApplicationService,
    LikeService,
    GplusService,
    GooglePlus,
    NativeStorage,
    FirebaseX,
    AppRate,
    Geolocation,
    SignInWithApple,
    InAppPurchase2,
    ImageDetailsResolver,
    AuthService,
    PhotoViewer,
    UserService,
    AuthcService,
    UsercService,
    ChatsService,
    UtilService,
    FollowService,
    StatusBar,
    NativeGeocoder,
    IonAvatar,
    Facebook,
    ActionSheetController,
    PopoverController,
    SocialSharing,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FirestoreSettingsToken, useValue: {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
