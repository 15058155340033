import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LikeService {

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    public userservice: UserService,
  ) { }

  isLiked(postId, currentuid) {
    return this.afs.collection<any>('/blogs/' + postId + '/liked', ref => ref.where('uid', '==', currentuid)).valueChanges();
  }

  Like(postId) {
    this.auth.getAuthState().subscribe(
      user => {
        if (user) {
          const currentuid = user.uid;
          let data = {
            uid: currentuid
          };
          this.afs.collection<any>('/blogs/' + postId + '/liked').doc(currentuid).set(data);
        }
      });
  }

  disLike(postId) {
    this.auth.getAuthState().subscribe(
      user => {
        if (user) {
          const currentuid = user.uid;
          // this.afs.collection<any>('/users/' + currentuid + '/following').doc(profileuid).delete();
          this.afs.collection<any>('/blogs/' + postId + '/liked').doc(currentuid).delete().then(
            () => {
              
            });
        }
    });
  }

  getFollowing(uid) {
    return this.afs.collection<any>('/users/' + uid + '/following').valueChanges();
  }
  
  getFollowers(uid) {
    return this.afs.collection<any>('/users/' + uid + '/followers').valueChanges();
  }
}
