import { HttpClientModule } from '@angular/common/http';
import { BlogComponent } from './../blog/blog.component';
import { EventComponent } from './../event/event.component';
import { JobsComponent } from './../jobs/jobs.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CreatepostPageRoutingModule } from './createpost-routing.module';

import { CreatepostPage } from './createpost.page';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    CreatepostPageRoutingModule
  ],
  entryComponents: [
    JobsComponent, EventComponent, BlogComponent
  ],
  exports: [
    JobsComponent, EventComponent, BlogComponent
  ],
  declarations: [CreatepostPage, JobsComponent, EventComponent, BlogComponent]
})
export class CreatepostPageModule {}
