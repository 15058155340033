import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HomepreferencePageRoutingModule } from './homepreference-routing.module';

import { HomepreferencePage } from './homepreference.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    HomepreferencePageRoutingModule
  ],
  declarations: [HomepreferencePage]
})
export class HomepreferencePageModule {}
