import { HelperService } from './../../services/helper.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.page.html',
  styleUrls: ['./feedback.page.scss'],
})
export class FeedbackPage implements OnInit {
  feedbackForm: FormGroup;
  wb;
  dp;
  displayName;
  userName;
  photoURL = '../../assets/images/default-profile.jpg';
  bannerURL;
  userid;
  type;
  isUser: boolean;


  constructor(
    private formBuilder: FormBuilder,
    private message: HelperService,
    private modalController: ModalController, 
    private db: AngularFirestore,
    private userService: UserService,
    private auth: AuthService) { 
      this.createForm();
    }

  ngOnInit() {
    this.getCurrentUser();
  }

  createForm() {
    this.feedbackForm = this.formBuilder.group({
      feedback: ['', Validators.required],
    });
  }

   get feedback() {
    return this.feedbackForm.get('feedback');
  }

  resetFields() {
    this.feedbackForm = this.formBuilder.group({
      feedback: new FormControl('', Validators.required)
    });
  }

  submitFeedback(feedbackForm) {
    if (!feedbackForm.valid) {
      this.message.messageToast('Fill your Feedback', 2000, 'top', 'danger');
    } else {
      this.db.collection('feedbacks').add({
        Feedback: feedbackForm.value.feedback,
        User: this.userName,
        Date: Date.now()
      });
      this.resetFields();
      this.close();
      this.message.messageToast('Thank You for your Feedback!', 9000, 'top', 'primary');
    }
  }

  getCurrentUser() {
    this.auth.getAuthState().subscribe(
      user => {
        if (user) {
          this.userService.retrieveUserDocument(user.uid).subscribe(
            userDoc => {
              if (userDoc) {
                this.displayName = userDoc.displayName;
                this.userName = userDoc.userName;
                this.wb = userDoc.Walletballance;
                this.dp = userDoc.displayPicture;
                this.type =userDoc.usertype;
                this.photoURL = userDoc.photoURL;
                this.userid = userDoc.uid;
              }
            });
        } else {

        }
    });
  }


  close(){
    this.modalController.dismiss();
   }
}
