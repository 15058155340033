import { AngularFireDatabase } from '@angular/fire/database';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HelperService } from '../services/helper.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ToastController, Platform } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/of';
import {  take, map } from 'rxjs/operators';
import { switchMap, shareReplay } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import * as geofirex from 'geofirex';
import { auth } from 'firebase/app';
import { Storage } from '@ionic/storage';


// const firestore = firebase.firestore();

interface User {
  uid?: string;
  email?: string;
  userName?: string;
  displayName?: string;
  displayPicture?: string;
  Age?: string;
  Businessname?: string;
  Gender?: string;
  Careerlevel?: string;
  Walletballance?: number;
  education?: string;
  Phonenumber?: string;
  Category?: string;
  Website?: string;
  Datefeatured?: any;
  Facebook?: string;
  Moreskill?: string;
  Instagram?: string;
  Twitter?: string;
  Googleplus?: string;
  Linkedin?: string;
  Bio?: string;
  joinDate?: any;
  isFeatured?: boolean;
  City?: string;
  Country?: string;
  Streetname?: string;
  Address?: string;
  State?: string;
  likes?: number;
  profileviews?: number;
  Followings?: number;
  Followers?: number;
  subscriberFree?: boolean;
  subscriberTrial?: boolean;
  subscriberBronze?: boolean;
  subscriberSilver?: boolean;
  subscriberGold?: boolean;
  usertype?: string;
  admin?: boolean;
  skill?: string;
  Online?: boolean;
  Datesubscribed?: any;
  Jobsposted?: number;
  Jobsapplied?: number;
  Eventsapplied?: number;
  Eventsposted?: number;
  Qualification?: string;
  Preferredjob?: string;
  Preferredskill?: string;
  shownotification?: boolean;
  showlocation?: boolean;
  showonline?: boolean;
  coordinates?: any;
  subexpiredate?: any;
  featureexpiredate?: any;
}



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  readonly path = 'users';
  lat;
  lng;
  wb;
  ja;
  jp;
  ea;
  ep;
  userid;
  email;
  dn;
  un;
  dp;
  ut;
  cat;
  cty;
  cntry;
  pv;

  private updateData: User;
  isFeatured: boolean;
  subscriberFree: boolean;
  subscriberBronze: boolean;
  subscriberSilver: boolean;
  subscriberGold: boolean;

  user: Observable<User>;

  userCollection: AngularFirestoreCollection<User>;
  userObs: Observable<any>;

  private authState: Observable<firebase.User>;
  private currentUser: firebase.User = null;

  constructor(
    private geolocation: Geolocation,
    private storage: Storage,
    private gplus: GooglePlus,
    private db : AngularFireDatabase,
    private platform: Platform,
    private afs: AngularFirestore,
    private message: HelperService,
    private toastController: ToastController,
    public afAuth: AngularFireAuth,
    private router: Router
  ) {
    this.getCurrentUserBallance();
     /// Get User and Auth data
    this.authState = this.afAuth.authState;
    this.authState.subscribe(user => {
       if (user) {
         this.currentUser = user;
       } else {
         this.currentUser = null;
       }
     });

     // Get Auth data, then get firestore user document || null
    this.user = this.afAuth.authState
       .switchMap(user => {
         if (user) {
           return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
         } else {
           return Observable.of(null);
         }
       });

    this.geolocation.getCurrentPosition().then((resp) => {
        // console.log(resp.coords.latitude, resp.coords.longitude)
        this.lat = resp.coords.latitude;
        this.lng = resp.coords.longitude;
       }).catch((error) => {
         console.log('Error getting location', error);
       });

  }

  uid() {
    return this.user
      .pipe(
        take(1),
        map(u => u && u.uid)
      )
      .toPromise();
  }

  getAuth() {
    return this.afAuth.auth;
  }

  viewUser(id: string, data: Partial<User>): Promise<void> {
    return this.afs.doc<User>(`${this.path}/${id}`).update(data);
  }
  followecount(id: string, data: Partial<User>): Promise<void> {
    return this.afs.doc<User>(`${this.path}/${id}`).update(data);
  }
  unfollowecount(id: string, data: Partial<User>): Promise<void> {
    return this.afs.doc<User>(`${this.path}/${id}`).update(data);
  }

  getAuthState() {
    return this.afAuth.authState;
  }

  register(userdata) {
    if (userdata.type === 'google') {
      // this.googleRegister();
    }
    if (userdata.type === 'twitter') {
      // this.twitterRegister();
    }
    if (userdata.type === 'email') {
      this.doRegister(userdata);
    }
  }

 

  public updateUserData(user) {

    // check if user already exists
    this.userCollection = this.afs.collection('users', ref => ref.where('uid', '==', user.uid));
    this.userObs = this.userCollection.valueChanges();
    this.userObs.forEach( userobj => {
      console.log('Existing User logged in- ', userobj[0].userName);
    })
    .then(
      (success) => {
        // this.router.navigateByUrl('/login');
      })
    .catch (
      (err) => {
        // setup user data in firestore on login
         
          console.log('New User login.\nSetting up user in database.');
          const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
          const geo = geofirex.init(firebase);
          const position = geo.point(this.lat, this.lng);
          const data: User = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            userName: user.userName,
            education: '',
            displayPicture: 'https://i.imgur.com/Olw5cj3.png',
            shownotification: true,
            usertype: user.usertype,
            Phonenumber: user.phonenumber,
            Category: '',
            Website: '',
            Moreskill: '',
            Facebook: '',
            Instagram: '',
            Twitter: '',
            Googleplus: '',
            Linkedin: '',
            Age: '',
            Gender: '',
            Careerlevel: '',
            Walletballance: user.Walletballance,
            Qualification: '',
            Businessname: '',
            Bio: '',
            City: '',
            Country: '',
            Streetname: '',
            Address: '',
            State: '',
            skill: '',
            Preferredjob: '',
            Preferredskill: '',
            isFeatured: false,
            Datefeatured: '',
            Datesubscribed: '',
            likes: 0,
            Jobsapplied: 0,
            Jobsposted: 0,
            Followings: 0,
            Followers: 0,
            profileviews: 0,
            subscriberFree: false,
            showonline: false,
            showlocation: false,
            subscriberTrial: true,
            subscriberBronze: false,
            subscriberSilver: false,
            subscriberGold: false,
            subexpiredate: new Date().setDate(new Date().getDate() + 30),
            Online: false,
            coordinates: position,
            joinDate: Date.now()
          };
          if (this.afAuth.auth.currentUser) {
            this.getAuth().currentUser.sendEmailVerification().then(() => {
              this.router.navigateByUrl('/verify-email');
            });
          }
          return userRef.set(data, { merge: true });
        });
  }

  logout() {
    this.afAuth.auth.signOut().then(
      () => {
      console.log('User logged out successfully.');
      this.router.navigate(['/login']);
    });
  }

  doRegister(formdata) {
   return new Promise<any>(() => {
     firebase.auth().createUserWithEmailAndPassword(formdata.email, formdata.password)
     .then(() => {
      this.getAuthState().subscribe(user => {
        if (user) {
          const userData = {
            uid: user.uid,
            email: user.email,
            displayName: formdata.displayname,
            userName: formdata.username,
            usertype: formdata.usertype,
            education: '',
            Phonenumber: formdata.phonenumber,
            Category: '',
            Website: '',
            Companysize: '',
            skill: '',
            Bio: '',
            Age: '',
            Gender: '',
            Careerlevel: '',
            Walletballance: 5000
          };
          this.updateUserData(userData);
          // this.db.object(`users/${user.uid}`).set({email: user.email, name: formdata.username, picture: 'https://i.imgur.com/Olw5cj3.png', showonline: true});
          // this.getAuth().currentUser.sendEmailVerification().then(() => {
          //   this.message.messageToast('Verification mail has been sent to your mail', 7000, 'top', 'primary');
          // });

        
      
        }
      });
    })
    // .then(() => this.doLogout())
    // .then(() => this.message.messageToast('Verification mail has been sent to your mail', 7000, 'top', 'primary'));
     
   });


  }


  async updateUser(displayname,
                   username, bio, edu, phone,
                   category, website, city,country,
                   streetname, address, state, age,
                   gender, clevel, qual, moreskill, email) {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      userName: username,
      displayName: displayname,
      Bio: bio,
      education: edu,
      Phonenumber: phone,
      Category: category,
      Website: website,
      City: city,
      Country: country, 
      Streetname: streetname, 
      Address: address,
      State: state,
      Age: age,
      Gender: gender,
      Careerlevel: clevel,
      Qualification: qual,
      Moreskill: moreskill,
      email: email,

    };



    const toast = await this.toastController.create({
      animated: true,
      position: 'top',
      cssClass: 'custom-alertDanger',
      color: 'primary',
      message: 'Profile updated successfully.',
      duration: 3000
    });


    return updateRef.update(this.updateData).then(
      () => {
        toast.present();
    });

  }
  async updatePreference(job, skill) {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Preferredjob: job,
      Preferredskill: skill

    };

    const toast = await this.toastController.create({
      animated: true,
      position: 'top',
      cssClass: 'custom-alertDanger',
      color: 'primary',
      message: 'Preference updated successfully.',
      duration: 3000
    });


    return updateRef.update(this.updateData).then(
      () => {
        toast.present();
    });

  }

  resetPw(email) {
    return this.afAuth.auth.sendPasswordResetEmail(email).then(
      () => {
        this.router.navigate(['emailrecover']);
    });
  }

  updateDetails(displayname, username) {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      userName: username,
      displayName: displayname,
    };
    return updateRef.update(this.updateData);
  }
 

  doLogin(email, password) {
   return new Promise<any>((resolve, reject) => {
     firebase.auth().signInWithEmailAndPassword(email, password)
     .then(
       res => resolve(res),
       err => reject(err));
   });
  }

  // doLogout() {
  //   return new Promise((resolve, reject) => {
  //     this.afAuth.auth.signOut()
  //     .then(() => {
  //       // this.firebaseService.unsubscribeOnLogOut();
  //       this.router.navigate(['login']);
  //       resolve();
  //     }).catch((error) => {
  //       console.log(error);
  //       reject();
  //     });
  //   });
  // }



  getCurrentUserBallance() {
    this.getAuthState().subscribe(
      user => {
        if (user) {
          this.retrieveUserDocument(user.uid).subscribe(
            userDoc => {
              if (userDoc) {
                this.wb = userDoc.Walletballance;
                this.ja = userDoc.Jobsapplied;
                this.jp = userDoc.Jobsposted;
                this.ea = userDoc.Eventsapplied;
                this.ep = userDoc.Eventsposted;
                this.userid = userDoc.uid;
                this.email = userDoc.email;
                this.dn = userDoc.displayName;
                this.un = userDoc.userName;
                this.dp = userDoc.displayPicture;
                this.ut = userDoc.usertype;
                this.cat = userDoc.Category;
                this.cty = userDoc.City;
                this.cntry = userDoc.Country;
                this.pv = userDoc.profileviews;
              }
            });
        } else {

        }
    });
  }

  facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.flogin(provider);
  }

  public flogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
    .then((credential) => {
      this.afs.doc('users/' + credential.user.uid).valueChanges().subscribe(
        user => {
        if (user) {
          this.router.navigateByUrl('/home');

        } else {
          this.afAuth.auth.signOut()
          .then(() => this.router.navigateByUrl('/login'));
        }
      });
    });
  }



  twitterLogin(){
    const provider = new firebase.auth.TwitterAuthProvider();
    return this.tlogin(provider);
  }



  public tlogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
    .then((credential) => {
      this.afs.doc('users/' + credential.user.uid).valueChanges().subscribe(
        user => {
        if (user) {
          this.router.navigateByUrl('/home');
        } else {
          this.afAuth.auth.signOut()
          .then(() => this.router.navigateByUrl('/login'));
        }
      });
    });
  }

  retrieveUserDocument(uid) {
    return this.afs.doc<any>('users/' + uid).valueChanges();
  }

  ApplyjobCount() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Jobsapplied: this.ja + 1
    };
    return updateRef.update(this.updateData).then(
      () => {
        // this.message.messageToast('Wallet funded with 10,000 Coins', 2000, 'top', 'primary');
        // 
        // console.log('wallet funded with 10,000');
      });
  }
  ApplyeventCount() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Eventsapplied: this.ea + 1
    };
    return updateRef.update(this.updateData).then(
      () => {
        // this.message.messageToast('Wallet funded with 10,000 Coins', 2000, 'top', 'primary');
        // 
        // console.log('wallet funded with 10,000');
      });
  }
  PosteventCount() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Eventsposted: this.ep + 1
    };
    return updateRef.update(this.updateData).then(
      () => {
        // this.message.messageToast('Wallet funded with 10,000 Coins', 2000, 'top', 'primary');
        // 
        // console.log('wallet funded with 10,000');
      });
  }
  ApplyPostCount() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Jobsposted: this.jp + 1
    };
    return updateRef.update(this.updateData).then(
      () => {
        // this.message.messageToast('Wallet funded with 10,000 Coins', 2000, 'top', 'primary');
        // 
        // console.log('wallet funded with 10,000');
      });
  }
  fund350() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 10000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 10,000 Coins', 2000, 'top', 'primary');
        this.router.navigateByUrl('wallet/' + this.userid)
        console.log('wallet funded with 10,000');
      });
  }
  fund500k() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 500000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 500,000 Coins', 2000, 'top', 'primary');
        this.router.navigateByUrl('wallet/' + this.userid)
       
      });
  }
  fund1m() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 1000000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 1,000,000 Coins', 2000, 'top', 'primary');
        this.router.navigateByUrl('wallet/' + this.userid)
       
      });
  }
  fund5m() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 5000000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 5,000,000 Coins', 2000, 'top', 'primary');
        this.router.navigateByUrl('wallet/' + this.userid)
       
      });
  }
  fund10m() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 10000000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 10,000,000 Coins', 2000, 'top', 'primary');
        this.router.navigateByUrl('wallet/' + this.userid)
       
      });
  }
  fund700() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 25000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 25,000 Coins', 2000, 'top', 'primary');

        console.log('wallet funded with 25,000');
      });
  }

  fund1400() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 50000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 50,000 Coins', 2000, 'top', 'primary');
        console.log('wallet funded with 50,000');
      });
  }
  featureJobweekly(id) {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb - 30000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.afs.collection('jobs').doc(id).update({
          isFeatured: true,
          Datefeatured: Date.now(),
          featureexpiredate: new Date().setDate(new Date().getDate() + 7)
        });
        this.router.navigateByUrl('wallet/' + this.userid)
        this.message.messageToast('Job Featured Successfully', 2000, 'top', 'primary');
      });
  }
  featureEventweekly(id) {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb - 30000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.afs.collection('feed').doc(id).update({
          isFeatured: true,
          Datefeatured: Date.now()
        });
        this.router.navigateByUrl('manageevent/' + this.userid)
        this.message.messageToast('Program Featured Successfully', 2000, 'top', 'primary');
      });
  }
  featureEventbiweekly(id) {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb - 50000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.afs.collection('feed').doc(id).update({
          isFeatured: true,
          Datefeatured: Date.now()
        });
        this.router.navigateByUrl('manageevent/' + this.userid)
        this.message.messageToast('Program Featured Successfully', 2000, 'top', 'primary');
      });
  }
  featureEventmonthly(id) {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb - 100000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.afs.collection('feed').doc(id).update({
          isFeatured: true,
          Datefeatured: Date.now()
        });
        this.router.navigateByUrl('manageevent/' + this.userid)
        this.message.messageToast('Program Featured Successfully', 2000, 'top', 'primary');
      });
  }
  featureJobbiweekly(id) {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb - 50000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.afs.collection('jobs').doc(id).update({
          isFeatured: true,
          Datefeatured: Date.now(),
          featureexpiredate: new Date().setDate(new Date().getDate() + 14),
        });
        this.router.navigateByUrl('wallet/' + this.userid)
        this.message.messageToast('Job Featured Successfully', 2000, 'top', 'primary');
      });
  }
  featureJobMonthly(id) {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb - 100000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.afs.collection('jobs').doc(id).update({
          isFeatured: true,
          Datefeatured: Date.now(),
          featureexpiredate: new Date().setDate(new Date().getDate() + 30)
        });

        this.router.navigateByUrl('wallet/' + this.userid)
        this.message.messageToast('Job Featured Successfully', 2000, 'top', 'primary');
      });
  }

  fund2500() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 100000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 100,000 Coins', 2000, 'top', 'primary');
        console.log('wallet funded with 1,000,000');
      });
  }

  fund4900() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 250000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 250,000 Coins', 2000, 'top', 'primary');
        console.log('wallet funded with 1,000,000');
      });
  }
  fund8900() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 500000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 500,000 Coins', 2000, 'top', 'primary');
        console.log('wallet funded with 1,000,000');
      });
  }
  fund16900() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 1000000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 1,000,000 Coins', 2000, 'top', 'primary');
        
        console.log('wallet funded with 1,000,000');
      });
  }
  fund33800() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 2500000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 2,500,000 Coins', 2000, 'top', 'primary');
        
        console.log('wallet funded with 1,000,000');
      });
  }
  fund55000() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 5000000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 5,000,000 Coins', 2000, 'top', 'primary');
        
        console.log('wallet funded with 1,000,000');
      });
  }
  fund6900() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 500000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 500,000 Coins', 2000, 'top', 'primary');
        
        console.log('wallet funded with 500,000');
      });
  }
  fund11500() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 1000000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 1,000,000 Coins', 2000, 'top', 'primary');
        console.log('wallet funded with 1,000,000');
      });
  }
  fund30900() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 5000000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 5,000,000 Coins', 2000, 'top', 'primary');
        
        console.log('wallet funded with 5,000,000');
      });
  }
  fund35900() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 10000000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Wallet funded with 10,000,000 Coins', 2000, 'top', 'primary');
        
        console.log('wallet funded with 10,000,000');
      });
  }
  enaableNotification() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      shownotification: true
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Notification Enabled', 2000, 'top', 'primary');
       
      });
  }
  disableNotification() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      shownotification: false
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Notification Disabled', 2000, 'top', 'primary');
      });
  }
  jobFee() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb  - 5
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Job Fee Deducted Successfully', 2000, 'top', 'primary');
        console.log('Job Fee Deducted');
      });
  }
  starterFee() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb  - 5000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Starter Plan Activated', 2000, 'top', 'primary');
        this.Bronze();
        
        console.log('Job Fee Deducted');
      });
  }
  advanceFee() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb  - 10000
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Advance Plan Activated', 2000, 'top', 'primary');
        this.Silver();
        
        console.log('Job Fee Deducted');
      });
  }
  featureJobFee() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb  - 500
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Feature Job Fee Deducted', 2000, 'top', 'primary');
        console.log('Job Fee Deducted');
      });
  }
  featureEventFee() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb  - 500
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Feature Event Fee Deducted', 2000, 'top', 'primary');
        console.log('Job Fee Deducted');
      });
  }
  featureProfileweekly() {
    if (this.wb >= 30000) {
      const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
      this.updateData = {
        Walletballance: this.wb  - 30000,
        isFeatured: true,
        Datefeatured: Date.now(),
        featureexpiredate: new Date().setDate(new Date().getDate() + 7),
      };
      return updateRef.update(this.updateData).then(
        () => {
          this.router.navigateByUrl('wallet/' + this.userid)
          this.message.messageToast('Profile Featured Successfully', 2000, 'top', 'primary');
        });
    } else {
      this.message.coinsToast('Insufficient Coins', 2000, 'top', 'danger');
    }
  }
  featureProfilebiweekly() {
    if (this.wb >= 50000) {
      const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
      this.updateData = {
        Walletballance: this.wb  - 50000,
        isFeatured: true,
        Datefeatured: Date.now(),
        featureexpiredate: new Date().setDate(new Date().getDate() + 14),
      };
      return updateRef.update(this.updateData).then(
        () => {
          this.router.navigateByUrl('wallet/' + this.userid)
          this.message.messageToast('Profile Featured Successfully', 2000, 'top', 'primary');
        });
    } else {
      this.message.coinsToast('Insufficient Coins', 2000, 'top', 'danger');
    }
  }
  featureProfilemonthly() {
    if (this.wb >= 100000) {
      const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
      this.updateData = {
        Walletballance: this.wb  - 100000,
        isFeatured: true,
        Datefeatured: Date.now(),
        featureexpiredate: new Date().setDate(new Date().getDate() + 30),
      };
      return updateRef.update(this.updateData).then(
        () => {
          this.router.navigateByUrl('wallet/' + this.userid)
          this.message.messageToast('Profile Featured Successfully', 2000, 'top', 'primary');
        });
    } else {
      this.message.coinsToast('Insufficient Coins', 2000, 'top', 'danger');
    }
  }
  gainCommentPoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb  + 10
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Comment Point Gained!', 2000, 'top', 'success');
      });
  }
  gainPostPoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb  + 0.001
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Post Point Gained!', 2000, 'top', 'success');
      });
  }
  gainLikePoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 10
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Like Point Gained!', 2000, 'top', 'success');
        console.log('Like Point Gained');
      });
  }
  gainJobpostPoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 50
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Job Post Point Gained!', 2000, 'top', 'success');
        console.log('Like Point Gained');
      });
  }
  gainProgrampostPoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 50
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Program Post Point Gained!', 2000, 'top', 'success');
        console.log('Like Point Gained');
      });
  }
  gainProfilepicPoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 50
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Program Post Point Gained!', 2000, 'top', 'success');
        console.log('Like Point Gained');
      });
  }
  gainGallerypicPoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 20
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Image Post Point Gained!', 2000, 'top', 'success');
        console.log('Like Point Gained');
      });
  }
  gainLikecommentPoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 10
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Like Point Gained!', 2000, 'top', 'success');
        console.log('Like Point Gained');
      });
  }
  lostLikecommentPoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb - 10
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Like Point Lost!', 2000, 'top', 'success');
        console.log('Like Point Gained');
      });
  }
  gainDislikecommentPoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 10
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Dislike Point Gained!', 2000, 'top', 'success');
        console.log('Like Point Gained');
      });
  }
  lostDislikecommentPoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb - 10
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Dislike Point Lost!', 2000, 'top', 'success');
        console.log('Like Point Gained');
      });
  }
  checkOnline() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Online: true
    };
    return updateRef.update(this.updateData).then(
      () => {
        console.log('i am online')
        // this.message.messageToast('Like Point Gained!', 2000, 'top', 'success');
        // console.log('Like Point Gained');
      });
  }
  notOnline() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Online: false
    };
    return updateRef.update(this.updateData).then(
      () => {this.logout()
        
        // this.message.messageToast('Like Point Gained!', 2000, 'top', 'success');
        // console.log('Like Point Gained');
      });
  }
  lostLikePoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb - 10
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Like Point lost!', 2000, 'top', 'success');
        console.log('Like Point Lost');
      });
  }
  gainFollowPoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb + 10
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Follow Point Gained!', 2000, 'top', 'success');

      });
  }
  lostFollowPoint() {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      Walletballance: this.wb - 10
    };
    return updateRef.update(this.updateData).then(
      () => {
        this.message.messageToast('Follow Point Lost!', 2000, 'top', 'success');

      });
  }

  updatedisplaypicture(dp) {
    const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
    this.updateData = {
      displayPicture: dp
    };
    return updateRef.update(this.updateData);
  }

 
  

 
  Bronze() {
    if (this.wb >= 180000) {
      const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
      this.updateData = {
        subscriberBronze: true,
        subscriberSilver: false,
        subscriberGold: false,
        subscriberFree: false,
        Datesubscribed: Date.now(),
        subexpiredate: new Date().setDate(new Date().getDate() + 365),
        subscriberTrial: false,
        Walletballance: this.wb - 180000
    };
      this.message.validationToast('Bronze Plan Activated!', 6000, 'top', 'success');
      this.router.navigateByUrl('wallet/' + this.userid);
      return updateRef.update(this.updateData);


    } else {
      this.message.coinsToast('Insufficient Coins', 6000, 'top', 'danger');
    }
  }
  Silver() {
    if (this.wb >= 250000) {
      const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
      this.updateData = {
        subscriberSilver: true,
        subscriberGold: false,
        subscriberBronze: false,
        subscriberFree: false,
        Datesubscribed: Date.now(),
        subexpiredate: new Date().setDate(new Date().getDate() + 365),
        subscriberTrial: false,
        Walletballance: this.wb - 250000
    };
      this.message.validationToast('Silver Plan Activated!', 6000, 'top', 'success');
      this.router.navigateByUrl('wallet/' + this.userid);
      return updateRef.update(this.updateData);
    } else {
      this.message.coinsToast('Insufficient Coins', 6000, 'top', 'danger');
    }
  }
  Gold() {
    if (this.wb >= 350000) {
      const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
      this.updateData = {
        subscriberGold: true,
        subscriberSilver: false,
        subscriberBronze: false,
        Datesubscribed: Date.now(),
        subexpiredate: new Date().setDate(new Date().getDate() + 365),
        subscriberFree: false,
        subscriberTrial: false,
        Walletballance: this.wb - 350000
    };
      this.message.validationToast('Gold Plan Activated!', 6000, 'top', 'success');
      this.router.navigateByUrl('wallet/' + this.userid);
      return updateRef.update(this.updateData);
    } else {
      this.message.coinsToast('Insufficient Coins', 6000, 'top', 'danger');
    }

  }

  // Check if user is logged in or not
  checkNotLogin() {
    this.afAuth.authState.subscribe(
      user => {
        if (user) {
          this.router.navigateByUrl('/home');
        }
      });
  }

  checkLogin() {
    this.afAuth.authState.subscribe(
      user => {
        if (!user) {
          this.router.navigate(['/login']);
        }
      });
  }

   ///// Role-based Authorization //////

  //  canRead(user: User): boolean {
  //   const allowed = ['admin', 'editor', 'subscriberFree'];
  //   return this.checkAuthorization(user, allowed);
  // }

  // canEdit(user: User): boolean {
  //   const allowed = ['admin', 'editor'];
  //   return this.checkAuthorization(user, allowed);
  // }

  // canDelete(user: User): boolean {
  //   const allowed = ['admin'];
  //   return this.checkAuthorization(user, allowed);
  // }





  // determines if user has matching role
  // public checkAuthorization(user: User, allowedRoles: string[]): boolean {
  //   if (!user) return false;
  //   for (const role of allowedRoles) {
  //     if ( user.roles[role] ) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }



}
