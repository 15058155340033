import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import * as geofirex from 'geofirex';
import { Observable, of } from 'rxjs';
import { switchMap, take, map } from 'rxjs/operators';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Platform } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple/ngx';



interface User {
  uid?: string;
  email?: string;
  userName?: string;
  displayName?: string;
  displayPicture?: string;
  Age?: string;
  Businessname?: string;
  Gender?: string;
  Careerlevel?: string;
  Walletballance?: number;
  education?: string;
  Phonenumber?: string;
  Category?: string;
  Website?: string;
  Datefeatured?: any;
  Facebook?: string;
  Moreskill?: string;
  Instagram?: string;
  Twitter?: string;
  Googleplus?: string;
  Linkedin?: string;
  Bio?: string;
  joinDate?: any;
  isFeatured?: boolean;
  City?: string;
  Country?: string;
  Streetname?: string;
  Address?: string;
  State?: string;
  likes?: number;
  profileviews?: number;
  Followings?: number;
  Followers?: number;
  subscriberFree?: boolean;
  subscriberTrial?: boolean;
  subscriberBronze?: boolean;
  subscriberSilver?: boolean;
  subscriberGold?: boolean;
  usertype?: string;
  admin?: boolean;
  skill?: string;
  Online?: boolean;
  Datesubscribed?: any;
  Jobsposted?: number;
  Jobsapplied?: number;
  Eventsapplied?: number;
  Eventsposted?: number;
  Qualification?: string;
  Preferredjob?: string;
  Preferredskill?: string;
  shownotification?: boolean;
  showlocation?: boolean;
  showonline?: boolean;
  coordinates?: any;
  subexpiredate?: any;
}


@Injectable({
  providedIn: 'root'
})
export class GplusService {

  lat;
  lng;

  user: Observable<User>;

  userCollection: AngularFirestoreCollection<User>;
  userObs: Observable<any>;

  private authState: Observable<firebase.User>;
  private currentUser: firebase.User = null;

  constructor(
    private afAuth: AngularFireAuth,
    private fb: Facebook,
    private signInWithApple: SignInWithApple,
    private geolocation: Geolocation,
    private router: Router,
    private afs: AngularFirestore,
    private gplus: GooglePlus,
    private platform: Platform,
    private loadingController: LoadingController,
    private storage: Storage
  ) {
    /// Get User and Auth data
    this.authState = this.afAuth.authState;
    this.authState.subscribe(user => {
      if (user) {
        this.currentUser = user;
      } else {
        this.currentUser = null;
      }
    });

    // Get Auth data, then get firestore user document || null
    this.user = this.afAuth.authState
      .switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return Observable.of(null);
        }
      });

    this.geolocation.getCurrentPosition().then((resp) => {
       // console.log(resp.coords.latitude, resp.coords.longitude)
       this.lat = resp.coords.latitude;
       this.lng = resp.coords.longitude;
      }).catch((error) => {
        console.log('Error getting location', error);
      });
  }



  getAuthState() {
    return this.afAuth.authState;
  }

  async signOut() {
    await this.afAuth.auth.signOut();
    return this.router.navigate(['/']);
  }

  async AppleSignIn(){
    this.signInWithApple.signin({
      requestedScopes: [
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
      ]
    }).then(async (res: AppleSignInResponse) => {
      const credential = new firebase.auth.OAuthProvider('apple.com').credential(
        res.identityToken
      );
      const response = await firebase.auth().signInWithCredential(credential).then(async () => {
        await firebase.auth().onAuthStateChanged(user => {
          if (user) {
            const userData = {
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              userName: user.uid,
              usertype: 'Freelancer',
              education: '',
              Phonenumber: '',
              Category: '',
              Website: '',
              Companysize: '',
              skill: '',
              Bio: '',
              Age: '',
              Gender: '',
              Careerlevel: '',
              Walletballance: 5000
            };
            this.updateUserData(userData);
          } 
        })
      })
      console.log('Login successful', response);
      console.log(JSON.stringify(res))
    })
    // var provider = new firebase.auth.OAuthProvider('apple.com')
    // const result = await firebase.auth().signInWithPopup(provider);    
    // console.log(result)
  }

  //// GOOGLE AUTH


  async googleLogin() {
    try {
      let user;

      if (this.platform.is('cordova')) {
        user = await this.nativeGoogleLogin();
      } else {

      }

    } catch (err) {
      console.log(err);
    }
  }

  async nativeGoogleLogin(): Promise<any> {
    const gplusUser = await this.gplus.login({
      webClientId:
        '654360006836-e0pmlb4rrdipn19hdf5ao3nlqkuqsfdr.apps.googleusercontent.com',
      offline: true,
      scopes: 'profile email'
    });

    return await this.afAuth.auth.signInWithCredential(
      auth.GoogleAuthProvider.credential(gplusUser.idToken)
    ).then(() => {
      this.getAuthState().subscribe(user => {
        if (user) {
          const userData = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            userName: user.uid,
            usertype: 'Freelancer',
            education: '',
            Phonenumber: '',
            Category: '',
            Website: '',
            Companysize: '',
            skill: '',
            Bio: '',
            Age: '',
            Gender: '',
            Careerlevel: '',
            Walletballance: 5000
          };
          this.updateUserData(userData);
          // this.db.object(`users/${user.uid}`).set({email: user.email, name: user.displayName, picture: 'https://i.imgur.com/Olw5cj3.png', showonline: true});

        }
      });
    })
  }


  async fblogin() {

    this.fb.login(['email'])
      .then((response: FacebookLoginResponse) => {
        this.onLoginSuccess(response);
        console.log(response.authResponse.accessToken);
      }).catch((error) => {
        console.log(error);
        alert('error:' + error);
      });
  }

  onLoginSuccess(res: FacebookLoginResponse) {
    // const { token, secret } = res;
    const credential = firebase.auth.FacebookAuthProvider.credential(res.authResponse.accessToken);
    this.afAuth.auth.signInWithCredential(credential)
      .then(() => {
        this.getAuthState().subscribe(user => {
          if (user) {
            const userData = {
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              userName: user.uid,
              usertype: 'Freelancer',
              education: '',
              Phonenumber: '',
              Category: '',
              Website: '',
              Companysize: '',
              skill: '',
              Bio: '',
              Age: '',
              Gender: '',
              Careerlevel: '',
              Walletballance: 5000
            };
            this.updateUserData(userData);
            // this.db.object(`users/${user.uid}`).set({email: user.email, name: user.displayName, picture: 'https://i.imgur.com/Olw5cj3.png', showonline: true});

          }
        });
      });

  }


  public updateUserData(user) {

    // check if user already exists
    this.userCollection = this.afs.collection('users', ref => ref.where('uid', '==', user.uid));
    this.userObs = this.userCollection.valueChanges();
    this.userObs.forEach( userobj => {
      console.log('Existing User logged in- ', userobj[0].userName);
    })
    .then(
      (success) => {
        this.router.navigateByUrl('/home');
      })
    .catch (
      (err) => {
        // setup user data in firestore on login
          console.log('New User login.\nSetting up user in database.');
          const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
          const geo = geofirex.init(firebase);
          const position = geo.point(this.lat, this.lng);
          const data: User = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            userName: user.userName,
            education: '',
            displayPicture: 'https://i.imgur.com/Olw5cj3.png',
            shownotification: true,
            usertype: user.usertype,
            Phonenumber: '',
            Category: '',
            Website: '',
            Moreskill: '',
            Facebook: '',
            Instagram: '',
            Twitter: '',
            Googleplus: '',
            Linkedin: '',
            Age: '',
            Gender: '',
            Careerlevel: '',
            Walletballance: user.Walletballance,
            Qualification: '',
            Businessname: '',
            Bio: '',
            City: '',
            Country: '',
            Streetname: '',
            Address: '',
            State: '',
            skill: '',
            Preferredjob: '',
            Preferredskill: '',
            isFeatured: false,
            Datefeatured: '',
            Datesubscribed: '',
            likes: 0,
            Jobsapplied: 0,
            Jobsposted: 0,
            Followings: 0,
            Followers: 0,
            profileviews: 0,
            subscriberFree: false,
            showonline: true,
            showlocation: true,
            subscriberTrial: true,
            subscriberBronze: false,
            subscriberSilver: false,
            subscriberGold: false,
            subexpiredate: new Date().setDate(new Date().getDate() + 30),
            Online: false,
            coordinates: position,
            joinDate: Date.now()
          };
          return userRef.set(data, { merge: true });
        }).then(() =>{
          this.router.navigateByUrl('/home');
        })
  }
}
