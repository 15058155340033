import { Skill } from './../../../models/skills';
import { HelperService } from './../../../services/helper.service';
import { ToastController, LoadingController } from '@ionic/angular';
import { Component, OnInit, NgZone, ɵclearResolutionOfComponentResourcesQueue } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UsernameValidators } from '../../../validators/username.validators';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import * as geofirex from 'geofirex';

interface User {
  uid?: string;
  email?: string;
  userName?: string;
  displayName?: string;
  displayPicture?: string;
  bannerPicture?: string;
  GalleryImage1?: string;
  GalleryImage2?: string;
  GalleryImage3?: string;
  GalleryImage4?: string;
  GalleryImage5?: string;
  status?: string;
  coordinates?: any;
  Age?: string;
  Businessname?: string;
  Gender?: string;
  Careerlevel?: string;
  Walletballance?: number;
  education?: string;
  Phonenumber?: string;
  Category?: string;
  Website?: string;
  Facebook?: string;
  Moreskill?: string;
  Instagram?: string;
  Twitter?: string;
  Googleplus?: string;
  Linkedin?: string;
  Bio?: string;
  joinDate?: any;
  isFeatured?: boolean;
  city?: string;
  country?: string;
  Streetname?: string;
  Address?: string;
  State?: string;
  Road?: string;
  likes?: number;
  profileviews?: number;
  Followings?: number;
  Followers?: number;
  subscriberFree?: boolean;
  subscriberBronze?: boolean;
  subscriberSilver?: boolean;
  subscriberGold?: boolean;
  editor?: boolean;
  admin?: boolean;
  usertype?: string;
  Jobsposted?: number;
  Jobsapplied?: number;
  Qualification?: string;
  Preferredjob?: string;
  Preferredskill?: string;
  Streetnumber?: string;
}



@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.page.html',
  styleUrls: ['./editprofile.page.scss'],
})
export class EditprofilePage implements OnInit {
  free;
  bronze;
  silver;
  gold;
  rid;
  trial;
  type;
  isTaken = false;
  usertype;
  lat;
  lng;
  isSubmitted = false;
  isSkillSubmitted = false;

  constructor(
    private fob: FormBuilder,
    public  auth: AuthService,
    public  router: Router,
    private storage: AngularFireStorage,
    public  ngZone: NgZone,
    private message: HelperService,
    public afAuth: AngularFireAuth,
    // private webview: WebView,
    public  toastCtrl: ToastController,
    public  loadingCtrl: LoadingController,
    private userService: UserService,
    private ar: ActivatedRoute,

    // private postService: GetUsersService,
    private afs: AngularFirestore
  ) {
    if (this.ar.snapshot.params['id']) {
      this.rid = this.ar.snapshot.paramMap.get('id');
      }

    this.authState = this.afAuth.authState;
    this.authState.subscribe(user => {
      if (user) {
        this.currentUser = user;
      } else {
        this.currentUser = null;
      }
    });
  }

  skillPost: Skill[] = [];

  skillForm: FormGroup;

  inter = [ 'Teamwork', 'Communication skills', 
  'Ledership', 'Teamplayer', 'Problem Solving', 'Decision Making', 
  'Empathy', 'Negociation', 'Written Communication', 'Assertiveness', 
  'Conflict Resolution', 'Dependability', 'Critical Thinking'];
  quals = [ 'Ph.D', 'MSc.', 'BSc.', 'A-level.', 'O-level', 'Others'];

  streetNumber: string = null;
  route: string = null;
  city? = '';
  state? = '';
  zipcode? = '';
  country? = '';

  isUser: boolean;

  photoURL = '../../assets/images/default-profile.jpg';
  userName = '';
  displayName = '';
  status = '';
  Moreskill = '';
  education = '';
  Phonenumber = '';
  Businessname = '';
  Aboutcompany = '';
  Aboutinstitution = '';
  Institutionname = '';
  Email = '';
  Qualification = '';
  Googleplus = '';
  Linkedin = '';
  Industry = '';
  Road = '';
  Bio = '';
  Age = '';
  Gender = '';
  Degree = '';
  Careerlevel = '';
  Category = '';
  Website = '';
  Instagram = '';
  Facebook = '';
  Twitter = '';
  Companysize = '';
  displayPicture = '';
  bannerPicture = '';
  GalleryImage1 = '';
  GalleryImage2 = '';
  GalleryImage3 = '';
  GalleryImage4 = '';
  GalleryImage5 = '';
  image: string = null;

  searchAddress = '';
  uid;

  currentusername;

  filename = 'Change Picture';
  inputFile;

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  private updateData: User;
  private authState: Observable<firebase.User>;
  private currentUser: firebase.User = null;

  categories = [
    'AC Installer',  'Actors', 'Actress', 'Barber', 'Back End Developer', 'Blogger', 'Bike Messenger', 'Bouncer/Security', 'Branding & Printing',  'Cake Designer & Pastries', 'Car Spray Painter', 'Carpenter', 'Chef', 'Cleaner', 'Content Writer', 'DJ', 'Drivers', 'Drum Player', 'Electrician', 'Electronics Device repairer', 'Event Planner', 'Fashion Illustrator', 'Film Director', 'Front End Developer', 'Full Stack Developer', 'Gate Man',  'Graphic Designer', 'Generator repairer', 'Gym Instructor', 'Guitarist', 'Hair Stylist', 'House Keeper', 'Interior Decorator', 'Iron Bender', 'Keyboardist', 'Laundry/Dry Cleaner', 'Make Up Artist', 'Manicure', 'Marketer',  'Masseuse', 'Mechanic', 'Mobile App Developer', 'Mobile Device/Laptop Engineer', 'MC/Comedian', 'Model', 'Motivational Speaker', 'Movie Extras', 'Music Artist', 'Nanny', 'Nail Technician', 'Nutritionist', 'OAP', 'Paint Artist', 'Pedicure', 'Photographer', 'Plumber', 'PR', 'Promotion & Services', ' Run way Model', 'Usher', 'Tailor/Fashion Designer', 'Satellite Engineer/Installer', 'Saxophonist', 'Shoe Maker', 'Social Media Influencer', 'Social Media Marketer', 'Spoken Word Artist', 'TV Host', 'UX/UI Designer', 'You tuber', 'Videographer', 'Video Editor', 'Vulcanizer', 'Waiter', 'Waitress', 'Wallpaper installer', 'Web Designer', 'Wedding Deco', 'Wedding Halls', 'Wedding Planner', 'Writer', 'Others'
  ];
  types = [
    'Fulltime', 'Partime', 'Remote'
  ];
  genders = [
    'Male', 'Female', 'Other'
  ];
  experiences = [
    'Amature', 'Intermediate', 'Professional'
  ];
  salaries = [
    '50-100', '250-400', '500-700'
  ];
  countries = [
    'Nigeria', 'Ghana'
  ];
  zips = [
    '32333', '12663'
  ];

  private unsubscribe$ = new Subject<void>();


  accountForm = new FormGroup({
    username: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      // UsernameValidators.cannotContainSpace,
      Validators.maxLength(15)
    ]),
    email: new FormControl('', [
      // Validators.required,
      // Validators.minLength(5),
      // Validators.maxLength(30)
    ]),
    ig: new FormControl('', [
      // Validators.required,
      // Validators.minLength(5),
      // Validators.maxLength(30)
    ]),
    tw: new FormControl('', [
      // Validators.required,
      // Validators.minLength(5),
      // Validators.maxLength(30)
    ]),
    gp: new FormControl('', [
      // Validators.required,
      // Validators.minLength(5),
      // Validators.maxLength(30)
    ]),
    li: new FormControl('', [
      // Validators.required,
      // Validators.minLength(5),
      // Validators.maxLength(30)
    ]),
    biography: new FormControl('', [
      // Validators.minLength(5),
      // Validators.required,
      // Validators.maxLength(100)
    ]),
    edu: new FormControl('', [
      // Validators.minLength(5),
      // Validators.required,
      // Validators.maxLength(100)
    ]),
    phone: new FormControl('', [
      // Validators.minLength(5),
      // Validators.required,
      // Validators.maxLength(100)
    ]),
    category: new FormControl('', [
      // Validators.minLength(5),
      // Validators.required,
      // Validators.maxLength(100)
    ]),
    bname: new FormControl('', [
      // Validators.minLength(5),
      // Validators.required,
      // Validators.maxLength(100)
    ]),
    moreskill: new FormControl([], [
      Validators.maxLength(1)
    ]),
    moreskill1: new FormControl([], [
      Validators.maxLength(3)
    ]),
    moreskill2: new FormControl([], [
      Validators.maxLength(5)
    ]),
    moreskill3: new FormControl([], [
      Validators.maxLength(1000)
    ]),
    website: new FormControl('', [
      // Validators.minLength(5),
      // Validators.required,
      // Validators.maxLength(100)
    ]),
    age: new FormControl('', [
      // Validators.minLength(1),
      // Validators.required,
      // Validators.maxLength(100)
    ]),
    qual: new FormControl('', [
      // Validators.minLength(1),
      // Validators.required,
      // Validators.maxLength(100)
    ]),
    gender: new FormControl('', [
      // Validators.minLength(1),
      // Validators.required,
      // Validators.maxLength(100)
    ]),
    aboutc: new FormControl('', [
      // Validators.minLength(1),
      // Validators.required,
      // Validators.maxLength(100)
    ]),
    clevel: new FormControl('', [
      // Validators.minLength(5),
      // Validators.required,
      // Validators.maxLength(100)
    ]),
    fb: new FormControl('', [
      // Validators.minLength(5),
      // Validators.required,
      // Validators.maxLength(100)
    ]),
    ut: new FormControl('', [
      // Validators.minLength(5),
      // Validators.required,
      // Validators.maxLength(100)
    ]),
    searchAddress: new FormControl('', [Validators.required]),
    streetNumber: new FormControl(this.streetNumber, []),
    route: new FormControl(this.route, []),
    city: new FormControl(this.city, []),
    state: new FormControl(this.state, []),
    zipcode: new FormControl(this.zipcode, []),
    country: new FormControl(this.country, []),
  });

  get username() {
    return this.accountForm.get('username');
  }

  setAddressFromMap(addressObj) {
    this.ngZone.run(() => {
      this.streetNumber = addressObj.street_number;
      this.route = addressObj.route;
      this.city = addressObj.locality;
      this.state = addressObj.admin_area_1;
      this.zipcode = addressObj.postal_code;
      this.country = addressObj.country;
    })
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': this.route + this.route + this.city }, (results, status) => {
          this.ngZone.run(() => {
            this.lat = results[0].geometry.location.lat();
            this.lng = results[0].geometry.location.lng();
            console.log(this.lat, this.lng);
          });
      });

  }

  ngOnInit() {
    this.createForm();
    this.auth.getAuthState().subscribe(
      currentuser => {
        if (currentuser) {
          this.userService.retrieveUserDocument(currentuser.uid).subscribe(
            user => {
              if (user) {
                this.displayName = user.displayName;
                this.currentusername = user.userName;
                this.userName = user.userName;
                this.photoURL = user.photoURL;
                this.status = user.status;
                this.Age = user.Age;
                this.trial = user.subscriberTrial;
                this.usertype = user.usertype;
                this.Degree = user.Degree;
                this.Aboutinstitution = user.Aboutinstitution;
                this.Institutionname = user.Institutionname;
                this.Instagram = user.Instagram;
                this.Facebook = user.Facebook;
                this.Instagram = user.Instagram;
                this.Twitter = user.Twitter;
                this.country = user.country;
                this.Googleplus = user.Googleplus;
                this.Linkedin = user.Linkedin;
                this.Aboutcompany = user.Aboutcompany;
                this.Businessname = user.Businessname;
                this.Industry = user.Industry;
                this.Qualification = user.Qualification;
                this.Email = user.email;
                this.Moreskill = user.Moreskill;
                this.type = user.usertype;
                this.Gender = user.Gender;
                this.Careerlevel = user.Careerlevel;
                this.displayPicture = user.displayPicture;
                this.bannerPicture = user.bannerPicture;
                this.GalleryImage1 = user.GalleryImage1;
                this.GalleryImage2 = user.GalleryImage2;
                this.GalleryImage3 = user.GalleryImage3;
                this.GalleryImage4 = user.GalleryImage4;
                this.GalleryImage5 = user.GalleryImage5;
                this.education = user.education;
                this.Phonenumber = user.Phonenumber;
                this.Category = user.Category;
                this.Website = user.Website;
                this.Companysize = user.Companysize;
                this.Bio = user.Bio;
                this.uid = user.uid;
                this.free = user.subscriberFree;
                this.bronze = user.subscriberBronze;
                this.silver = user.subscriberSilver;
                this.gold = user.subscriberGold;
              }
            });
        } else {
          this.router.navigateByUrl('');
        }
      });

    this.getSkills();
  }

  createForm() {
    this.skillForm = this.fob.group({
      interpersonalskills: ['', Validators.required ],
      otherskill: [''],
      skilllevel: [0, Validators.required],
    });
  }

  getSkills() {
    this.getAllproskills()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(result => {
    this.skillPost = result;
    });
  }

  getAllproskills(): Observable<Skill[]> {
    // tslint:disable-next-line:max-line-length
    const skills = this.afs.collection<Skill>('interpersonalskills', ref => ref.where('user', '==', this.rid)).snapshotChanges().pipe(
      map(actions => {
        return actions.map(
          c => ({
            skillId: c.payload.doc.id,
            ...c.payload.doc.data()
          }));
      }));
    return skills;
  }



  search($event) {
    const q = $event.target.value;
    this.checkUsername(q);
  }

  checkUsername(q) {
    this.afs.collection('users', ref => ref.where('userName', '==', q)).valueChanges().subscribe(user => {
      const searchuser: any = user[0];
      if (user[0] && this.currentusername !== searchuser.userName) {
          this.isTaken = true;
      } else {
        this.isTaken = false;
      }
    });
  }

  async updateUser() {
    if (!this.accountForm.valid || this.isTaken || this.username.errors) {
      this.isSubmitted = true;
      this.message.validationToast('Fill all Required Fields', 2000, 'top', 'danger')
    } else {

      const updateRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${this.currentUser.uid}`);
      const geo = geofirex.init(firebase);
      const position = geo.point(this.lat, this.lng);
      this.updateData = {
        userName:  this.userName,
        displayName: this.displayName,
        Bio: this.Bio,
        education: this.education,
        Phonenumber:  this.Phonenumber,
        Category: this.Category,
        coordinates: position,
        Website: this.Website,
        city: this.city,
        Road: this.route || null,
        country: this.country,
        Streetnumber: this.streetNumber || null,
        State: this.state,
        Age: this.Age,
        Gender: this.Gender,
        Careerlevel: this.Careerlevel,
        Qualification: this.Qualification,
        Moreskill: this.Moreskill,
        email: this.Email,
        Instagram: this.Instagram,
        Facebook: this.Facebook,
        Twitter: this.Twitter,
        Linkedin: this.Linkedin,
        Googleplus: this.Googleplus,
        usertype: this.usertype
      };
  
  
  
      const toast = await this.toastCtrl.create({
        animated: true,
        position: 'top',
        cssClass: 'custom-alertDanger',
        color: 'primary',
        message: 'Profile updated successfully.',
        duration: 3000
      });
  
  
      return updateRef.update(this.updateData).then(
        () => {
          toast.present().then(() => this.router.navigateByUrl('user/' + this.userName))
      });
      

    }
   
  }

  Delete(skillId: string) {
    this.deleteskill(skillId).then(
      () => {

      }
    );
  }

  deleteskill(skillId: string) {
    return this.afs.doc('interpersonalskills/' + skillId).delete();
  }



  resetFields() {
    this.skillForm = this.fob.group({
      interpersonalskills: new FormControl('', Validators.required),
      skilllevel: new FormControl(0, Validators.required),
    });
  }

  onSubmit(skillForm) {
    if (!skillForm.valid) {
      this.isSkillSubmitted = true;
      this.message.validationToast('Fill all required fields', 2000, 'top', 'danger')
    } else {
      this.afs.collection('interpersonalskills').add({
        interpersonalskills: skillForm.value.interpersonalskills,
        skilllevel: skillForm.value.skilllevel,
        otherskill: skillForm.value.otherskill,
        user: this.userName
      })
    .then(
      res => {
        this.resetFields();
      }
    );
    }
  }

}
