import { PlaceSearchDirective } from './place-search.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { EditEventPageRoutingModule } from './edit-event-routing.module';
import { EditEventPage } from './edit-event.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    EditEventPageRoutingModule
  ],
  declarations: [EditEventPage, PlaceSearchDirective]
})
export class EditEventPageModule {}
