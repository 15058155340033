import { IonhelperService } from './../../helper/ionhelper.service';
import { PostsService } from './../../services/posts.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../services/auth.service';
import { HelperService } from './../../services/helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Job } from './../../models/jobs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import * as geofirex from 'geofirex';

@Component({
  selector: 'app-editjob',
  templateUrl: './editjob.page.html',
  styleUrls: ['./editjob.page.scss'],
})
export class EditjobPage implements OnInit {

  isSubmitted = false;
  lat;
  lng;

  dP;
  dN;
  mail;
  userid;
  image;
  jobData = new Job();
  jobId;
  private unsubscribe$ = new Subject<void>();
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  editjobForm: FormGroup;
  submitType: string;
  formType: string;
  dataPostedUrl: string;
  isNewForm: Observable<boolean>;
  isLoading = false;
  streetNumber = '';
  route = '';
  city = '';
  state = '';
  zipcode = '';
  country = '';


  experiences = [
    'Amature', 'Intermediate', 'Professional'
  ];

  categories = [
    'Photographer', 'Videographer', 'Make Up Artist', 'Model', ' Run way Modal', 'Usher', 'Bouncer/Security', ' Tailor/Fashion Designer',
    'Fashion Illustrator', 'Paint Artist', 'Mechanic', 'Plumber', 'Electrician', 'Satallite Engineer/Installer', 'Carpenter',
    'Mobile Device/Laptop Engineer', 'Wallpaper installer', ' Interior Decorator', 'Cake Designer & Pastries', 'Chef', 'MC/Comedian',
    'TV Host', 'DJ', 'Drivers', 'Music Artist', 'Actors', 'Actress', 'Movie Extras',
     'Electronics Device repairer', 'AC Installer', 'Cleaner',
    'Nutritionist', 'Gym Instructor', 'Car Spray painter', 'Bike Messenger', 'Graphic Designer',
     'Web Designer', 'Web Developer', 'Mobile App Designer',
    'UX Designer', 'Laundry/Dry Cleaner', 'Wedding Halls', 'OAP',
    'Bolgger', 'Writer', 'Film Director', 'Waiter', 'Waitress', 'Generator repairer'
  ];

  experience =['Ammature', 'Intermediate', 'Professional'];
  types = ['Part-time', 'Full-time'];
  genders = ['Male', 'Female', 'Others'];
  quals = [ 'Ph.D', 'MSc.', 'BSc.', 'A-level.', 'O-level', 'Others'];
  curs = [
    'N', '$', '#'
  ]

  constructor(
    private postService: PostsService,
    private storage: AngularFireStorage,
    private router: Router,
    private userService: UserService,
    private auth: AuthService,
    private message: HelperService,
    private formBuilder: FormBuilder,
    private zone: NgZone,
    private routerr: ActivatedRoute,
    private db: AngularFirestore,
    private ion: IonhelperService
  ) { 
    if (this.routerr.snapshot.params['id']) {
      this.jobId = this.routerr.snapshot.paramMap.get('id');
      }
    this.editjobform();
  }

  ngOnInit() {
    this.getCurrentUser();
    this.postService.getJobbyId(this.jobId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
    (result: Job) => {
    this.jobData = result;
    }
    );
    this.image = this.jobData.image;
  }

  editjobform() {
    this.editjobForm = this.formBuilder.group({
      title: [this.jobData.title, Validators.required],
      description: [this.jobData.description, Validators.required],
      category: [this.jobData.category, Validators.required],
      experience: [this.jobData.experience, Validators.required],
      type: [this.jobData.type, Validators.required],
      qualification: [this.jobData.qualification, Validators.required],
      gender: [this.jobData.gender, Validators.required],
      currency: [this.jobData.currency, Validators.required],
      salary: [this.jobData.salary, Validators.required],
      deadline: [this.jobData.deadline, Validators.required],
      streetNumber: [this.jobData.streetnumber],
      route: [this.jobData.route],
      city: [this.jobData.city],
      state: [this.jobData.state],
      country: [this.jobData.country],
      searchAddress: ['', Validators.required]
    });
  }

  getCurrentUser() {
    this.auth.getAuthState().subscribe(
      user => {
        if (user) {
          this.userService.retrieveUserDocument(user.uid).subscribe(
            userDoc => {
              if (userDoc) {
                this.dP = userDoc.displayPicture;
                this.dN = userDoc.displayName;
                this.mail = userDoc.email;
                this.userid = userDoc.uid;
              }
            });
        } else {

        }
    });
  }

  setAddressFromMap(addressObj) {
    this.zone.run(() => {
      this.streetNumber = addressObj.street_number;
      this.route = addressObj.route;
      this.city = addressObj.locality;
      this.state = addressObj.admin_area_1;
      this.zipcode = addressObj.postal_code;
      this.country = addressObj.country;
    });
  }

  uploadImage(event) {
    const file = event.target.files[0];
    const path = `jobimages/${file.name}`;
    if ( file.type.split('/')[0] !== 'image') {
      return alert('Only Image Files');
    } else {
      this.ion.presentLoading('Uploading Image', 4000)
      const task = this.storage.upload(path, file);
      const ref = this.storage.ref(path);
      this.uploadPercent = task.percentageChanges();
      console.log('Image uploaded!');
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL();
          this.downloadURL.subscribe(url => (this.image = url));
        })
      )
      .subscribe();
    }
  }

  onSubmit(editjobForm){
    if(!editjobForm.valid ) {
      this.isSubmitted = true;
      this.message.validationToast('Please Fill All Required Fields', 2000, 'top', 'danger');
    } else {
    this.updateJob(editjobForm)
    .then(
      res => {
        this.message.messageToast('Job Updated Successfully', 2000, 'top', 'primary');
        this.router.navigate(['/home']);
      }
    )
    }
  }

  updateJob(editjobForm) {
    const geo = geofirex.init(firebase);
    const position = geo.point(this.lat, this.lng);
    return this.db.doc('jobs/' + this.jobId).update({
      title: editjobForm.value.title,
      description: editjobForm.value.description,
      category: editjobForm.value.category,
      coordinates: position,
      experience: editjobForm.value.experience,
      type: editjobForm.value.type,
      qualification: editjobForm.value.qualification,
      gender: editjobForm.value.gender,
      currency: editjobForm.value.currency,
      salary: editjobForm.value.salary,
      deadline: editjobForm.value.deadline,
      streetnumber: this.streetNumber || null,
      route: this.route || null,
      country: this.country,
      city: this.city,
      state: this.state,
      image: this.image,
      companyname: this.dN,
      views: this.jobData.views,
      aboutemployer: this.jobData.aboutemployer,
      instagram: this.jobData.instagram,
      facebook: this.jobData.facebook,
      twitter: this.jobData.twitter,
      googleplus: this.jobData.googleplus,
      createdDate: this.jobData.createdDate,
      authorimage: this.jobData.authorimage,
      isFeatured: this.jobData.isFeatured,
      authorId: this.jobData.authorId
    });
  }

}
