import { HelperService } from './../../services/helper.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../services/auth.service';
import { HomepreferencePage } from './../homepreference/homepreference.page';
import { Router } from '@angular/router';
import { IonhelperService } from './../../helper/ionhelper.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.page.html',
  styleUrls: ['./setting.page.scss'],
})
export class SettingPage implements OnInit {

  myValue = true;

  shownotification;
  showlocation;
  showonline;
  uid;

  constructor(
    private modalController: ModalController,
    public auth: AuthService,
    private message: HelperService,
    private db: AngularFirestore,
    private ion: IonhelperService,
    private userService: UserService,
    private router: Router) { }

  ngOnInit() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.auth.getAuthState().subscribe(
      user => {
        if (user) {
          this.userService.retrieveUserDocument(user.uid).subscribe(
            userDoc => {
              if (userDoc) {
                this.shownotification = userDoc.shownotification;
                this.showlocation = userDoc.showlocation;
                this.showonline = userDoc.showonline;
                this.uid = userDoc.uid;
              }
            });
        } else {

        }
    });
  }

  notificationChange() {
    const shownotification = this.shownotification === true ? false : true;
    this.db.collection('users').doc(this.uid).update({shownotification});
 }
  locationChange() {
    const showlocation = this.showlocation === true ? false : true;
    this.db.collection('users').doc(this.uid).update({showlocation});
 }
  onlineChange() {
    const showonline = this.showonline === true ? false : true;
    this.db.collection('users').doc(this.uid).update({showonline});
 }
  close() {
   this.modalController.dismiss();
  }
  passwordsetting() {
    this.router.navigate(['/passwordsetting']);
    this.modalController.dismiss();
  }
  payment(){
    this.router.navigate(['subcription']);
    this.modalController.dismiss();
  }
  homeper(){
    this.ion.presentModal(HomepreferencePage, 'user', 'homepre');
    this.modalController.dismiss();
  }
}
